import React, { useEffect, useState } from 'react';
import {
  IconButton,
  InputAdornment,
  Box,
  CircularProgress,
} from '@mui/material';
import { Form, Field, useFormikContext } from 'formik';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { TextField as FormikTextField } from 'formik-mui';
import useDebounce from 'hooks/useDebounce';
import useUIContext from 'context/UIContext';

const SearchInputForm = ({
  handleSubmit,
  values,
  dirty,
  resetForm,
  reset,
  isLoading,
  setSearchTerm,
  placeholder,
  focused,
  onSearchInputBlur,
  shouldFixOnFocus,
  disabled = false,
}: any) => {
  const formikContext = useFormikContext();
  const { rightSideBarFixed, fixRightSidebar, direction } = useUIContext();
  const [wasSidebarFixed, setIsSidebarFixed] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!isFocused) setIsSidebarFixed(rightSideBarFixed);
  }, [rightSideBarFixed, isFocused]);

  console.log(formikContext?.values);

  useDebounce(
    () => {
      setSearchTerm && setSearchTerm(values?.search);
    },
    [formikContext?.values],
    300
  );

  return (
    <Form
      onKeyDown={e => {
        if (e.key === 'Enter') {
          handleSubmit();
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'stretch',
          width: '100%',
          fontSize: '.8rem',
        }}>
        <Field
          dir={direction}
          disabled={disabled}
          component={FormikTextField}
          sx={{ width: '100%' }}
          name='search'
          variant='outlined'
          type='text'
          placeholder={placeholder}
          autoFocus={!!focused}
          autoComplete={false}
          InputProps={{
            sx: { fontSize: '.9rem', width: '100%', height: '2.5rem' },
            startAdornment: (
              <InputAdornment position='start'>
                {isLoading && values.search !== '' ? (
                  <CircularProgress size={20} sx={{ mr: 0.65 }} />
                ) : (
                  <SearchIcon color='secondary' sx={{ opacity: 0.75 }} />
                )}
              </InputAdornment>
            ),
            endAdornment: values.search ? (
              <InputAdornment position='end'>
                <IconButton
                  size='small'
                  onClick={() => (dirty ? resetForm() : reset())}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ) : (
              ''
            ),
          }}
          onFocus={() => {
            setIsFocused(true);
            if (shouldFixOnFocus) fixRightSidebar(true);
          }}
          onBlur={() => {
            if (shouldFixOnFocus) fixRightSidebar(wasSidebarFixed);
            setIsFocused(false);
            if (onSearchInputBlur) onSearchInputBlur();
          }}
        />
      </Box>
    </Form>
  );
};

export default SearchInputForm;
