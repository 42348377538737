/* eslint-disable no-shadow */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/prefer-default-export, no-shadow
export enum ReportType {
  POSITION_REPORT = 'position_report',
  IGNITION_ON = 'ignition_on',
  IGNITION_OFF = 'ignition_off',
  MOVING_START = 'moving_start',
  MOVING_END = 'moving_end',
  TURN = 'turn',
  DISTANCE = 'distance',
  PANIC_START = 'panic_start',
  PANIC_END = 'panic_end',
  PANIC_EVENT = 'panic_event',
  SPEEDING_START = 'speeding_start',
  SPEEDING_END = 'speeding_end',
  HARSH_ACCELERATION = 'harsh_acceleration',
  HARSH_BRAKE = 'harsh_brake',
  HARSH_TURN = 'harsh_turn',
  ACCIDENT_DETECTED = 'accident_detected',
  REBOOT = 'reboot',
  POWER_DISCONNECTED = 'power_disconnected',
  POWER_CONNECTED = 'power_connected',
  EXCESS_IDLING_START = 'excess_idling_start',
  EXCESS_IDLING_END = 'excess_idling_end',
  DRIVER_LOGGED_IN = 'driver_logged_in',
  DRIVER_LOGGED_OUT = 'driver_logged_out',
  ANTENNA_DISCONNECTED = 'antenna_disconnected',
  ANTENNA_CONNECTED = 'antenna_connected',
  GPS_JAMMING_START = 'gps_jamming_start',
  GPS_JAMMING_END = 'gps_jamming_end',
  UNKNOWN = 'unknown',
  AIS_REPORT = 'ais_report',
  CAMERA_IMAGE = 'camera_image',
}