import React from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/named
import { AddCircleOutline, Delete, Edit } from '@mui/icons-material';
import { Button, IconButton, Tooltip } from '@mui/material';
// eslint-disable-next-line import/named
import { GridColDef } from '@mui/x-data-grid';
import BoxedContainer from 'components/BoxedContainer';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import Table from 'components/Table';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import { useDeleteRole, useRoles } from 'hooks/roles/useRoles';

const commonColDefOptions = {
  // resizable: true,
  flex: 1,
  headerAlign: 'left',
  align: 'left',
  sortable: false,
};

const getColumns = ({
  isDeleting,
  handleEditRole,
  handleDeleteRole,
  canWrite,
  t,
}: any): GridColDef[] => [
  //@ts-ignore
  { field: 'name', headerName: 'Name', ...commonColDefOptions }, //@ts-ignore
  {
    field: 'description',
    headerName: 'Description',
    ...commonColDefOptions,
    renderCell: ({ row }: any) => {
      return t(row?.description);
    },
  }, //@ts-ignore
  {
    field: 'permissions',
    headerName: 'Permissions',
    ...commonColDefOptions,
    align: 'center',
    minWidth: 90,
    maxWidth: 120,
    flex: 0.5,
    renderCell: ({ row }: any) => {
      if (row?.permissions.length === 0) return <div>0 permissions</div>;

      return (
        <Tooltip
          title={
            <div>
              {row?.permissions?.map((p: any) => (
                <div key={p?.feature}>
                  {p?.feature}:{p?.permission}
                </div>
              ))}
            </div>
          }>
          <div>{`${row?.permissions?.length} permissions`}</div>
        </Tooltip>
      );
    },
  }, //@ts-ignore
  ...(canWrite('ROLE')
    ? [
        {
          headerName: '',
          ...commonColDefOptions,
          minWidth: 90,
          maxWidth: 100,
          align: 'center',
          flex: 0.5,
          renderCell: (r: any) => {
            return (
              <>
                {r?.row?.description.includes('default_role_') ? null : (
                  <Tooltip title='Edit role'>
                    <IconButton
                      disabled={isDeleting}
                      onClick={() => handleEditRole(r?.row?.roleId)}>
                      <Edit fontSize='small' />
                    </IconButton>
                  </Tooltip>
                )}
                <Tooltip title='Delete role'>
                  <IconButton
                    disabled={isDeleting}
                    onClick={() => handleDeleteRole(r?.row)}>
                    <Delete fontSize='small' />
                  </IconButton>
                </Tooltip>
              </>
            );
          },
        },
      ]
    : []),
];

const Roles = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useRoles() as any;
  const { mutate: deleteRole, isLoading: isDeleting } = useDeleteRole() as any;
  const { setActive, reset } = useActionMenuContext();
  const { canWrite } = usePermissions();
  const { openRightSidebar } = useUIContext();
  const [deleteDialog, setDeleteDialog] = React.useState<any>(null);

  React.useEffect(() => {
    return () => reset();
  }, []);

  const handleEditRole = (id: any) => {
    setActive(ActionMenuModuleTypes.AddRole, { roleId: id });
    openRightSidebar();
  };

  const handleDeleteRole = (role: any) => {
    setDeleteDialog(role);
  };

  const handleAddrole = () => {
    setActive(ActionMenuModuleTypes.AddRole);
    openRightSidebar();
  };

  return (
    <BoxedContainer>
      {canWrite('ROLE') && (
        <div style={{ textAlign: 'right' }}>
          <Button
            onClick={handleAddrole}
            color='secondary'
            variant='outlined'
            startIcon={<AddCircleOutline />}
            sx={{ minWidth: 140 }}>
            {t('add_role')}
          </Button>
        </div>
      )}
      <Table
        onPageChange={(newPage: number) => null}
        data={data}
        isLoading={isLoading}
        getRowId={(row: any) => row.roleId}
        columns={getColumns({
          isDeleting,
          handleEditRole,
          handleDeleteRole,
          canWrite,
          t,
        })}
        pageSize={12}
        sx={{ mt: 2 }}
      />
      <ConfirmDeleteDialog
        open={!!deleteDialog}
        onCancel={() => {
          setDeleteDialog(null);
        }}
        onConfirm={() => {
          if (deleteDialog) deleteRole({ id: deleteDialog?.roleId });
        }}
        title={'Confirm to delete asset'}
        content={
          <>
            Are you sure you want to delete <b>{deleteDialog?.name}</b>?
          </>
        }
      />
    </BoxedContainer>
  );
};

export default Roles;
