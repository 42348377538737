import {
  Alert,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Typography,
} from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import {
  useBillingSettings,
  useProjectedMonthlyBill,
  useUpdateBillingSettings,
} from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import errorMessage from 'services/errorMessage';
import CostHistory from './CostHistory';
import CostProjection from './CostProjection';
import InvoiceList from './InvoiceList';
import InvoiceRecipients from './InvoiceRecipients';

const FormFields = ({ mutationError }: any) => {
  const { t, i18n } = useTranslation();
  const {
    values,
    dirty,
    isSubmitting,
    resetForm,
    submitForm,
    isValid,
    setFieldValue,
    errors,
  }: any = useFormikContext();
  const [serverError, setServerError] = useState<any>();

  useEffect(() => {
    if (isSubmitting) {
      setServerError(null);
    }
  }, [isSubmitting]);

  useEffect(() => {
    setServerError(null);
  }, [values]);

  useEffect(() => {
    setServerError(mutationError);
  }, [mutationError]);
  return (
    <Form>
      <Collapse in={serverError}>
        <Alert variant='outlined' severity='error' sx={{ mb: 1 }}>
          {errorMessage(t, serverError)}
        </Alert>
      </Collapse>
      <Grid container xs={12} columnSpacing={1}>
        <Grid item xs={2}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceOrgName'
              type='text'
              variant='outlined'
              label={t('billing.invoiceOrgName')}
              placeholder={t('billing.invoiceOrgName')}
            />
          </div>
        </Grid>
        <Grid item xs={3}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceBillingAddressLine'
              type='text'
              variant='outlined'
              label={t('billing.invoiceBillingAddressLine')}
              placeholder={t('billing.invoiceBillingAddressLine')}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceBillingAddressCity'
              type='text'
              variant='outlined'
              label={t('billing.invoiceBillingAddressCity')}
              placeholder={t('billing.invoiceBillingAddressCity')}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceBillingAddressRegion'
              type='text'
              variant='outlined'
              label={t('billing.invoiceBillingAddressRegion')}
              placeholder={t('billing.invoiceBillingAddressRegion')}
            />
          </div>
        </Grid>
        <Grid item xs={1}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceBillingAddressPostcode'
              type='text'
              variant='outlined'
              label={t('billing.invoiceBillingAddressPostcode')}
              placeholder={t('billing.invoiceBillingAddressPostcode')}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceBillingAddressCountry'
              type='text'
              variant='outlined'
              label={t('billing.invoiceBillingAddressCountry')}
              placeholder={t('billing.invoiceBillingAddressCountry')}
            />
          </div>
        </Grid>
        <Grid item xs={2}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceBillingAddressAttentionTo'
              type='text'
              variant='outlined'
              label={t('billing.invoiceBillingAddressAttentionTo')}
              placeholder={t('billing.invoiceBillingAddressAttentionTo')}
            />
          </div>
        </Grid>
        <Grid item xs={1.5}>
          <div className='pv-10'>
            <Field
              component={FormikTextField}
              sx={{ width: '100%' }}
              name='invoiceVatNumber'
              type='text'
              variant='outlined'
              label={t('billing.invoiceVatNumber')}
              placeholder={t('billing.invoiceVatNumber')}
            />
          </div>
        </Grid>
        <Grid item xs={8.5}>
          <div className='pv-10'>
            <InvoiceRecipients />
          </div>
        </Grid>
      </Grid>
      <Divider sx={{ my: 1 }} />
      {isSubmitting && <LinearProgress />}
      <div style={{ display: 'flex', flex: 1, justifyContent: 'right' }}>
        <Button
          onClick={submitForm}
          variant='contained'
          color='primary'
          sx={{ width: '25%' }}
          disabled={!isValid}>
          {t('billing.update_billing_settings')}
        </Button>
      </div>
    </Form>
  );
};

const Billing = () => {
  const { data } = useBillingSettings() as any;
  const { data: projectedData, isLoading: projectedLoading } =
    useProjectedMonthlyBill() as any;
  const { mutate: update, error } = useUpdateBillingSettings();

  const onSubmit = (payload: any) => {
    update(payload);
  };

  return (
    <>
      <Grid container xs={11.5} spacing={2}>
        <Grid container xs={12} sx={{ flex: 1 }}>
          <Grid item xs={6} sx={{ flex: 1, height: '100%' }}>
            <Box sx={{ px: 2, flex: 1, height: '100%' }}>
              <CostProjection
                projection={projectedData}
                isLoading={projectedLoading}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ flex: 1, height: '100%' }}>
            <Box sx={{ px: 2, flex: 1, height: '100%' }}>
              <CostHistory />
            </Box>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box>
            {data ? (
              <Formik
                isInitialValid={false}
                initialValues={data}
                enableReinitialize
                // validationSchema={UserProfileFormSchema(t)}
                onSubmit={(values, { setSubmitting }) => {
                  setSubmitting(false);
                  onSubmit(values);
                }}>
                <FormFields mutationError={error} />
              </Formik>
            ) : (
              <Skeleton variant='rectangular' sx={{ height: '150px' }} />
            )}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6' sx={{ my: 2 }}>
            Invoices
          </Typography>
          <InvoiceList />
        </Grid>
      </Grid>
    </>
  );
};

export default Billing;
