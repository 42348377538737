/* eslint-disable max-len */
import {
  ApartmentTwoTone,
  DomainDisabledTwoTone,
  EditLocationAltTwoTone,
  ExploreTwoTone,
  LabelOffTwoTone,
  LabelTwoTone,
  LayersTwoTone,
  Lock,
  MapTwoTone,
  PublicTwoTone,
  RestartAlt,
  SailingTwoTone,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import bbox from '@turf/bbox';
import AISRegionVesselImageOrange from 'assets/ais-region-vessel-mini-orange.svg';
import AISRegionVesselImagePink from 'assets/ais-region-vessel-mini-pink.svg';
import AISRegionVesselImagePurple from 'assets/ais-region-vessel-mini-purple.svg';
import AISRegionVesselImageTeal from 'assets/ais-region-vessel-mini-teal.svg';
import AISRegionVesselImageYellow from 'assets/ais-region-vessel-mini-yellow.svg';
import AISRegionVesselImage from 'assets/ais-region-vessel-mini.svg';
import AssetLabelBackground from 'assets/asset-label-marker.svg';
import HeadingImagePanic from 'assets/heading-mini-panic.svg';
import HeadingImage from 'assets/heading-mini.svg';
import LocationImage from 'assets/location-mini.png';
import SecurityCameraFovImage from 'assets/security-camera-fov.svg';
import ShippingLanes from 'assets/shipping-lanes-v1.json';
import StaticAssetPipeMonitorImage from 'assets/static-asset-pipemonitor-mini.svg';
import StaticAssetWellheadImage from 'assets/static-asset-wellhead-mini.svg';
import VesselAisImage from 'assets/vessel-ais-mini.svg';
import VesselImagePanic from 'assets/vessel-mini-panic.svg';
import VesselImage from 'assets/vessel-mini.svg';
import {
  AISRegionSource,
  AISRegionVesselSource,
  AssetClusters,
  AssetClustersCount,
  AssetLocationSource,
  AssetPopup,
  ClusterPopup,
  ContextMenuPopup,
  DeviceHeading,
  DevicePoint,
  HoverPopup,
  LAYERS,
  LineStringLayer,
  LineStringSource,
  LocationPointLayer,
  LocationPopup,
  LocationSource,
  Outline,
  Polygon,
  SOURCES,
  VesselAisLocation,
  VesselLocation,
} from 'components/Map';
import AltMap from 'components/Map/AltMap';
import AssetLabel from 'components/Map/constants/layers/AssetLabel';
import CameraFOV from 'components/Map/constants/layers/CameraFOV';
import CameraLocation from 'components/Map/constants/layers/CameraLocation';
import CustomDrawLayerStyle from 'components/Map/constants/layers/CustomDrawLayerStyle';
import ShippingLaneLayer from 'components/Map/constants/layers/ShippingLaneLayer';
import ShippingLaneSource from 'components/Map/constants/sources/ShippingLaneSource';
import StaticAssetSecurityCameraSource from 'components/Map/constants/sources/StaticAssetSecurityCameraSource';
import MapStyles from 'components/Map/constants/styles';
import StyleSelectorControl from 'components/Map/controls/V2StyleSelectorControl';
import ConfirmDeleteDialog from 'components/SystemDialogs/ConfirmDeleteDialog';
import useActionMenuContext, {
  ActionMenuModuleTypes,
} from 'context/ActionMenuContext';
import usePermissions from 'context/PermissionContext';
import useUIContext from 'context/UIContext';
import {
  useAISRegionVesselDetails,
  useAISVesselLocationsByRegions,
  useAssetDetails,
  useAssetLocations,
  useAssetTripHistory,
  useCurrentUser,
  useDeleteLocation,
  useLinkStaticAssets,
  useLocalStorage,
  useLocationDetails,
  useLocations,
  useStaticAssetPipelines,
  useStaticAssets,
  useUnlinkStaticAssets,
  useUpdateCurrentUserSettings,
} from 'hooks';
import useIgnitionLockout from 'hooks/assets/useIgnitionLockout';
import useReboot from 'hooks/assets/useReboot';
import useRequestLocation from 'hooks/assets/useRequestLocation';
import mapboxgl, { GeoJSONSource, LngLat, LngLatLike } from 'mapbox-gl';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import deviceTypes from '../../common-web/definitions/deviceTypes.json';
import * as countryCentroids from '../../components/Map/constants/countryCentroids.json';
import AISRegionLayer from '../../components/Map/constants/layers/AISRegionLayer';
import AISRegionVesselLocation from '../../components/Map/constants/layers/AISRegionVesselLocation';
import Buildings3DLayer from '../../components/Map/constants/layers/Buildings3DLayer';
import PipelineLineAnimationLayer from '../../components/Map/constants/layers/PipelineLineAnimationLayer';
import PipelineLineLayer from '../../components/Map/constants/layers/PipelineLineLayer';
import PipelineMonitor from '../../components/Map/constants/layers/PipelineMonitor';
import StaticAssetConnectorBounds from '../../components/Map/constants/layers/StaticAssetConnectorBounds';
import WellheadLocation from '../../components/Map/constants/layers/WellheadLocation';
import AISRegionVesselPopup from '../../components/Map/constants/popups/AISRegionVesselPopup';
import PipelineSource from '../../components/Map/constants/sources/PipelineSource';
import StaticAssetPipeMonitorSource from '../../components/Map/constants/sources/StaticAssetPipeMonitorSource';
import StaticAssetPipelineConnectorSource from '../../components/Map/constants/sources/StaticAssetPipelineConnectorSource';
import StaticAssetWellheadSource from '../../components/Map/constants/sources/StaticAssetWellheadSource';
import {
  useAISRegionVesselColors,
  useAISRegions,
  useUpsertAISRegionVesselColor,
} from '../../hooks/aisRegions/useAISRegion';
const getCountryISO3 = require('country-iso-2-to-3');

const assetHeadingsImage: any = {
  id: 'heading',
  src: HeadingImage,
  type: 'svg',
};
const assetHeadingsImagePanic: any = {
  id: 'heading_panic',
  src: HeadingImagePanic,
  type: 'svg',
};
const locationImage: any = {
  id: 'location',
  src: LocationImage,
};
const vesselAisImage: any = {
  id: 'vessel_ais',
  src: VesselAisImage,
  type: 'svg',
};
const vesselImage: any = {
  id: 'vessel',
  src: VesselImage,
  type: 'svg',
};
const vesselImagePanic: any = {
  id: 'vessel_panic',
  src: VesselImagePanic,
  type: 'svg',
};
const aisRegionVesselImage: any = {
  id: 'ais_region_vessel_',
  src: AISRegionVesselImage,
  type: 'svg',
};
const aisRegionVesselImagePurple: any = {
  id: 'ais_region_vessel_purple',
  src: AISRegionVesselImagePurple,
  type: 'svg',
};
const aisRegionVesselImageOrange: any = {
  id: 'ais_region_vessel_orange',
  src: AISRegionVesselImageOrange,
  type: 'svg',
};
const aisRegionVesselImagePink: any = {
  id: 'ais_region_vessel_pink',
  src: AISRegionVesselImagePink,
  type: 'svg',
};
const aisRegionVesselImageTeal: any = {
  id: 'ais_region_vessel_teal',
  src: AISRegionVesselImageTeal,
  type: 'svg',
};
const aisRegionVesselImageYellow: any = {
  id: 'ais_region_vessel_yellow',
  src: AISRegionVesselImageYellow,
  type: 'svg',
};
const staticAssetWellheadImage: any = {
  id: 'static_asset_wellhead_image',
  src: StaticAssetWellheadImage,
  type: 'svg',
};
const staticAssetPipeMonitorImage: any = {
  id: 'static_asset_pipemonitor_image',
  src: StaticAssetPipeMonitorImage,
  type: 'svg',
};
const assetLabelImage: any = {
  id: 'asset_label_background',
  src: AssetLabelBackground,
  type: 'svg',
  props: {
    pixelRatio: 3.6,
  },
};

const securityCameraFovImage: any = {
  id: 'security_camera_fov_image',
  src: SecurityCameraFovImage,
  type: 'svg',
};
// const assetLabelIgnitionImage: any = {
//   id: 'asset_label_background_ignition',
//   src: AssetLabelIgnitionBackground,
//   type: 'svg',
//   props: {
//     pixelRatio: 3.6,
//   },
// };
// const assetLabelIdlingImage: any = {
//   id: 'asset_label_background_idling',
//   src: AssetLabelIdlingBackground,
//   type: 'svg',
//   props: {
//     pixelRatio: 3.6,
//   },
// };
const locationPointLayer: any = LocationPointLayer();
const locationBoundsLayer: any = Polygon({
  id: LAYERS.BOUNDS,
  source: SOURCES.LOCATIONS,
});
const locationBoundsOutlineLayer: any = Outline({
  id: LAYERS.OUTLINE,
  source: SOURCES.LOCATIONS,
});
const vesselAisLocations: any = VesselAisLocation({
  id: LAYERS.VESSEL_LOCATIONS_AIS,
  source: SOURCES.ASSET_LOCATIONS,
});
const vesselLocations: any = VesselLocation({
  id: LAYERS.VESSEL_LOCATIONS,
  source: SOURCES.ASSET_LOCATIONS,
});
const aisRegionsVesselLocations: any = AISRegionVesselLocation({
  id: LAYERS.AIS_REGION_VESSEL_LOCATION,
  source: SOURCES.AIS_REGION_VESSEL_LOCATION,
});
const aisRegionsLayer: any = AISRegionLayer({
  id: LAYERS.AIS_REGION_LAYER_OUTLINE,
  source: SOURCES.AIS_REGIONS,
});
const assetLocations: any = DevicePoint({
  id: LAYERS.ASSET_POINTS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetHeadings: any = DeviceHeading({
  id: LAYERS.ASSET_HEADINGS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetsClusters: any = AssetClusters({
  id: LAYERS.ASSET_CLUSTERS,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetsClustersCount: any = AssetClustersCount({
  id: LAYERS.ASSET_CLUSTERS_COUNT,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetTripHistoryLayer: any = LineStringLayer({
  id: LAYERS.TRIP_VEHICLE_LINE,
  source: SOURCES.LINE_STRING,
  opacity: 0.4,
  dash: [3, 4],
  size: 2,
});
const staticAssetWellheadLocations: any = WellheadLocation({
  id: LAYERS.STATIC_ASSET_WELLHEAD_LOCATION,
  source: SOURCES.STATIC_ASSET_WELLHEAD_LOCATIONS,
});
const staticAssetPipeMonitorLocations: any = PipelineMonitor({
  id: LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION,
  source: SOURCES.STATIC_ASSET_PIPEMONITOR_LOCATIONS,
});
const staticAssetWellheadConnectorBounds: any = StaticAssetConnectorBounds({
  id: LAYERS.STATIC_ASSET_PIPELINE_CONNECTOR_BOUNDS,
  source: SOURCES.STATIC_ASSET_PIPELINE_CONNECTOR_BOUNDS,
});
const staticAssetPipelineConnectorBounds: any = StaticAssetConnectorBounds({
  id: LAYERS.STATIC_ASSET_PIPELINE_CONNECTOR_BOUNDS,
  source: SOURCES.STATIC_ASSET_PIPELINE_CONNECTOR_BOUNDS,
});
const pipelineLineLayer: any = PipelineLineLayer({
  id: LAYERS.STATIC_ASSET_PIPELINE_LINE_LAYER,
  source: SOURCES.STATIC_ASSET_PIPELINE_SOURCE,
});
const pipelineAnimationLineLayer: any = PipelineLineAnimationLayer({
  id: LAYERS.STATIC_ASSET_PIPELINE_LINE_ANIMATION_LAYER,
  source: SOURCES.STATIC_ASSET_PIPELINE_SOURCE,
});
const assetLabelAssetsLayer: any = AssetLabel({
  id: LAYERS.ASSET_LABELS_MOVING,
  source: SOURCES.ASSET_LOCATIONS,
});
const assetLabelStaticAssetsLayer: any = AssetLabel({
  id: LAYERS.ASSET_LABELS_STATIC,
  source: SOURCES.STATIC_ASSET_CAMERA_LOCATIONS,
});
const securityCameraLayer: any = CameraLocation({
  id: LAYERS.STATIC_ASSET_SECURITY_CAMERAS,
  source: SOURCES.STATIC_ASSET_CAMERA_LOCATIONS,
});
const securityCameraFovLayer: any = CameraFOV({
  id: LAYERS.STATIC_ASSET_SECURITY_CAMERAS_FOV,
  source: SOURCES.STATIC_ASSET_CAMERA_LOCATIONS,
});
const shippingLaneLayer: any = ShippingLaneLayer({
  id: LAYERS.CONSTANT_SHIPPING_LANE_LAYER,
  source: SOURCES.CONSTANT_SHIPPING_LANE_SOURCE,
});

const buildingsLayer: any = Buildings3DLayer();

const CLUSTER_LAYERS = [LAYERS.ASSET_CLUSTERS, LAYERS.ASSET_CLUSTERS_COUNT];
const ASSET_LAYERS = [
  LAYERS.ASSET_POINTS,
  LAYERS.ASSET_HEADINGS,
  LAYERS.VESSEL_LOCATIONS,
  LAYERS.VESSEL_LOCATIONS_AIS,
  LAYERS.AIS_REGION_VESSEL_LOCATION,
  LAYERS.STATIC_ASSET_WELLHEAD_LOCATION,
  LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION,
  LAYERS.STATIC_ASSET_SECURITY_CAMERAS,
];
const LOCATION_LAYERS = [LAYERS.LOCATIONS, LAYERS.BOUNDS];

const isLayerClickable = (layer: LAYERS) => {
  if (layer === LAYERS.BOUNDS) return false;
  return Object.values(LAYERS).includes(layer);
};

const getClickableLayers = (layers: any) => {
  return layers
    .filter((layer: any) =>
      [
        LAYERS.ASSET_POINTS,
        LAYERS.ASSET_CLUSTERS,
        // LAYERS.ASSET_CLUSTERS_COUNT,
        LAYERS.VESSEL_LOCATIONS,
        LAYERS.VESSEL_LOCATIONS_AIS,
        LAYERS.LOCATIONS,
        LAYERS.STATIC_ASSET_WELLHEAD_LOCATION,
        LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION,
      ].includes(layer.id)
    )
    .map((layer: any) => ({
      id: layer.id,
      source: layer.source,
    }));
};

// FIXME: This is a temporary solution to get the correct property name by layer ID
const getIdPropertyForLayer = (layer: LAYERS) => {
  switch (layer) {
    case LAYERS.VESSEL_LOCATIONS:
    case LAYERS.VESSEL_LOCATIONS_AIS:
    case LAYERS.ASSET_POINTS:
    case LAYERS.ASSET_HEADINGS:
    case LAYERS.STATIC_ASSET_WELLHEAD_LOCATION:
    case LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION:
    case LAYERS.STATIC_ASSET_SECURITY_CAMERAS:
      return 'assetId';
    case LAYERS.LOCATIONS:
    case LAYERS.BOUNDS:
      return 'locationId';
    case LAYERS.AIS_REGION_VESSEL_LOCATION:
      return 'mmsi';
    default:
      return 'id';
  }
};

const DEFAULT_CENTER = { lng: 25, lat: 13 };
const DEFAULT_ZOOM = 2;

const MapView = () => {
  const mapRef = useRef<any>();
  const { t } = useTranslation();
  const { canWrite, permissions } = usePermissions();
  const canWriteRef = useRef<boolean>(false);
  const {
    rightSideBarWidth,
    rightSideBarOpen,
    openRightSidebar,
    closeRightSidebar,
    fixRightSidebar,
    direction,
    isMobile,
  } = useUIContext();
  const [initialMapCenter, setInitialMapCenter] =
    useState<LngLatLike>(DEFAULT_CENTER);
  const [mapCenter, setMapCenter] = useState<LngLatLike>(DEFAULT_CENTER);
  const [initialMapZoom, setInitialMapZoom] = useState<number>(DEFAULT_ZOOM);
  const [mapZoom, setMapZoom] = useState<number>(DEFAULT_ZOOM);
  const [locationsDataSource, setLocationsDataSource] = useState<any>();
  const tripHistoryDiscardable = useRef<boolean>(false);
  const [assetLocationsDataSource, setAssetLocationsDataSource] =
    useState<any>();
  const [assetTripHistorySource, setAssetTripHistorySource] = useState<any>();
  const [aisRegionVesselSource, setAISRegionVesselSource] = useState<any>();
  const [aisRegionsDataSource, setAISRegionsDataSource] = useState<any>();
  const [bounds, setBounds] = useState<any>(null);
  const [popup, setPopup] = useState<any>(null);
  const popupLayerRef = useRef<any>({ layer: null });
  const [hoverPopup, setHoverPopup] = useState<any>(null);
  const [contextMenu, setContextMenu] = useState<any>(null);
  const [_, setDrawnFeatures] = useState<any>([]);
  const {
    activateAddLocation,
    setAddLocationData,
    mainMap,
    setDrawMode,
    reset,
    setMainMapField,
    setActive,
    resetDrawnFeatures,
  } = useActionMenuContext();
  const [clickableLayers, setClickableLayers] = useState([]);
  const popupIdRef = useRef<number | null>(null);
  const [flyTo, setFlyTo] = useState<any>(null);
  const { data: locationData } = useLocations() as any;
  const { data: assetLocationData } = useAssetLocations() as any;
  const { data: aisVesselLocationData } =
    useAISVesselLocationsByRegions() as any;
  const { data: aisRegionsData } = useAISRegions() as any;
  const { mutate: deleteLocationRequest } = useDeleteLocation();
  const [activeMapFeature, setActiveMapFeature] = useState<any>(null);
  const [deleteLocation, setDeleteLocation] = useState<any>();
  const [deletePipeline, setDeletePipeline] = useState<any>();
  const [rebootDevice, setRebootDevice] = useState<any>();
  const [lockoutDevice, setLockoutDevice] = React.useState<any>();
  const location = useLocation();
  const { data: staticAssetData } = useStaticAssets() as any;
  const { data: pipelineData } = useStaticAssetPipelines({}) as any;
  const [
    staticAssetWellheadLocationsDataSource,
    setStaticAssetWellheadLocationsDataSource,
  ] = useState<any>();
  const [
    staticAssetPipeMonitorLocationsDataSource,
    setStaticAssetPipeMonitorLocationsDataSource,
  ] = useState<any>();
  const [
    staticAssetPipelineConnectorBoundsSource,
    setStaticAssetPipelineConnectorBoundsSource,
  ] = useState<any>();
  const [
    staticAssetCameraLocationsDataSource,
    setStaticAssetCameraLocationsDataSource,
  ] = useState<any>();
  const [pipelineDataSource, setPipelineDataSource] = useState<any>();
  const [drawModeOptions, setDrawModeOptions] = useState<any>({});
  const pipelineConnectorBounds = useRef<any>(null);
  const { mutate: linkStaticAssets } = useLinkStaticAssets() as any;
  const { mutate: unlinkStaticAssets } = useUnlinkStaticAssets() as any;
  const currentStep = useRef<number>(0);
  const navigate = useNavigate();
  const [storedMapLocation, storeMapLocation] = useLocalStorage(
    'mapLocation',
    DEFAULT_CENTER
  );
  const [storedMapZoom, storeMapZoom] = useLocalStorage(
    'mapZoom',
    DEFAULT_ZOOM
  );
  const [storedMapProjection, storeMapProjection] = useLocalStorage(
    'mapProjection',
    'mercator'
  );
  const [stored3DBuildings, store3DBuildings] = useLocalStorage(
    'map3dBuildings',
    false
  );
  const [storedMapStyle, storeMapStyle] = useLocalStorage('mapStyle', '');
  const { mutate: updateUserSetting } = useUpdateCurrentUserSettings() as any;
  const { data: assetDetails } = useAssetDetails({
    asset: activeMapFeature?.asset,
  }) as any;
  const { data: assetHistory } = useAssetTripHistory({
    asset: activeMapFeature?.asset,
  }) as any;
  const { data: locationDetails } = useLocationDetails({
    location: activeMapFeature?.location,
  }) as any;
  const { data: aisRegionVesselDetails } = useAISRegionVesselDetails({
    mmsi: activeMapFeature?.mmsi,
  }) as any;
  const {
    data: aisRegionVesselColors,
    isLoading: isAisRegionVesselColorsLoading,
  } = useAISRegionVesselColors() as any;
  const { mutate: upsertAisRegionVesselColor } =
    useUpsertAISRegionVesselColor();
  const isPipelineMode = useRef<number | null>(null);
  const [isPipelineModeOn, setIsPipelineModeOn] = useState<boolean>(false);
  const animating = useRef<any>(null);
  const { i18n } = useTranslation();
  const { data: userData } = useCurrentUser() as any;
  const ZOOM_DURATION = 200;
  const ZOOM_OPTIONS = { duration: ZOOM_DURATION };
  // const [startZoomInterval, clearZoomInterval] = useInterval();
  const [showStyleSelectorMenu, setShowStyleSelectorMenu] =
    useState<boolean>(false);
  const [showAssetLabels, setShowAssetLabels] = useState<boolean | null>(null);
  const [showShippingLanes, setShowShippingLanes] = useLocalStorage(
    'showShippingLines',
    true
  );
  const showAssetLabelsRef = useRef<boolean>(false);
  const { setFlashMessage } = useUIContext();
  const { handleClick: requestLocation } = useRequestLocation();
  const { handleClick: requestReboot } = useReboot();
  const { handleClick: requestIgnitionLockout } = useIgnitionLockout();

  const shippingLaneSource = useMemo(() => {
    return ShippingLaneSource(ShippingLanes);
  }, [ShippingLanes]);

  useEffect(() => {
    fixRightSidebar(false);
    closeRightSidebar();
    setInitialMapCenter(storedMapLocation);
    setInitialMapZoom(storedMapZoom);
    setClickableLayers(getClickableLayers(getLayers()));
    if (!!canWrite) canWriteRef.current = canWrite('LOCATION');
    return () => {
      reset();
      mapRef.current = null;
    };
  }, []);

  const getLayers = useCallback(() => {
    if (isPipelineModeOn) {
      return [
        staticAssetWellheadConnectorBounds,
        staticAssetPipelineConnectorBounds,
        staticAssetWellheadLocations,
        staticAssetPipeMonitorLocations,
      ];
    }

    return [
      ...(stored3DBuildings ? [buildingsLayer] : []),
      ...(showShippingLanes && MapStyles?.maritime === storedMapStyle
        ? [shippingLaneLayer]
        : []),
      aisRegionsVesselLocations,
      aisRegionsLayer,
      assetTripHistoryLayer,
      locationBoundsLayer,
      locationBoundsOutlineLayer,
      locationPointLayer,
      securityCameraLayer,
      securityCameraFovLayer,
      assetLocations,
      assetHeadings,
      vesselAisLocations,
      vesselLocations,
      pipelineLineLayer,
      pipelineAnimationLineLayer,
      staticAssetPipeMonitorLocations,
      staticAssetWellheadLocations,
      ...(showAssetLabels ? [assetLabelAssetsLayer] : []),
      ...(showAssetLabels ? [assetLabelStaticAssetsLayer] : []),
      assetsClusters,
      assetsClustersCount,
    ];
  }, [
    isPipelineModeOn,
    showAssetLabels,
    showShippingLanes,
    storedMapStyle,
    stored3DBuildings,
  ]);

  const getSources = useCallback(() => {
    if (isPipelineModeOn) {
      return [
        pipelineDataSource,
        staticAssetPipelineConnectorBoundsSource,
        staticAssetWellheadLocationsDataSource,
        staticAssetPipeMonitorLocationsDataSource,
      ];
    }

    return [
      shippingLaneSource,
      locationsDataSource,
      assetLocationsDataSource,
      assetTripHistorySource,
      aisRegionVesselSource,
      aisRegionsDataSource,
      pipelineDataSource,
      staticAssetWellheadLocationsDataSource,
      staticAssetPipeMonitorLocationsDataSource,
      staticAssetCameraLocationsDataSource,
    ];
  }, [
    ...(showShippingLanes && MapStyles?.maritime === storedMapStyle
      ? [shippingLaneSource]
      : []),
    isPipelineModeOn,
    locationsDataSource,
    assetLocationsDataSource,
    assetTripHistorySource,
    aisRegionVesselSource,
    aisRegionsDataSource,
    pipelineDataSource,
    staticAssetPipelineConnectorBoundsSource,
    staticAssetWellheadLocationsDataSource,
    staticAssetPipeMonitorLocationsDataSource,
    staticAssetCameraLocationsDataSource,
    storedMapStyle,
  ]);

  useEffect(() => {
    if (!!canWrite) canWriteRef.current = canWrite('LOCATION');
  }, [canWrite]);

  useEffect(() => {
    if (storedMapLocation !== mapCenter && mapCenter !== DEFAULT_CENTER) {
      storeMapLocation(mapCenter);
    }
  }, [mapCenter, storedMapLocation]);

  useEffect(() => {
    if (!userData?.settings) return;
    if (
      userData?.settings?.countryCode &&
      storedMapLocation.lat === DEFAULT_CENTER.lat &&
      storedMapLocation.lng === DEFAULT_CENTER.lng
    ) {
      const countryCode = userData?.settings?.countryCode;
      const countryConfig = (countryCentroids as any)[countryCode];
      if (countryConfig) {
        const { longitude, latitude, boundingBox } = countryConfig;
        if (boundingBox) {
          setBounds(boundingBox);
        } else {
          setInitialMapCenter([longitude, latitude]);
          setMapCenter([longitude, latitude]);
          setMapZoom(15);
        }
      }
    }
  }, [userData]);

  useEffect(() => {
    if (storedMapZoom !== mapZoom && mapZoom !== DEFAULT_ZOOM) {
      storeMapZoom(mapZoom);
    }
  }, [mapZoom, storedMapZoom]);

  useEffect(() => {
    if (locationData && locationData?.locations?.length > 0)
      setLocationsDataSource(LocationSource(locationData?.locations));
  }, [locationData?.locations]);

  useEffect(() => {
    if (aisRegionsData && aisRegionsData?.length > 0)
      setAISRegionsDataSource(AISRegionSource(aisRegionsData));
  }, [aisRegionsData]);

  useEffect(() => {
    if (assetLocationData && assetLocationData.length > 0)
      setAssetLocationsDataSource(AssetLocationSource(assetLocationData));
  }, [assetLocationData]);

  useEffect(() => {
    if (staticAssetData && staticAssetData.length > 0) {
      setStaticAssetWellheadLocationsDataSource(
        StaticAssetWellheadSource(
          staticAssetData.filter((i: any) => 'wellhead' === i.assetType)
        )
      );
      setStaticAssetPipeMonitorLocationsDataSource(
        StaticAssetPipeMonitorSource(
          staticAssetData.filter((i: any) => 'pipeline' === i.assetType)
        )
      );
      setStaticAssetCameraLocationsDataSource(
        StaticAssetSecurityCameraSource(
          staticAssetData.filter((i: any) => 'security_camera' === i.assetType)
        )
      );
      pipelineConnectorBounds.current = staticAssetData;
    }
  }, [staticAssetData]);

  useEffect(() => {
    if (!pipelineData || pipelineData.length === 0) return;

    let sourceData: any[] = [];
    pipelineData.forEach((item: any) => {
      if (!item || !item.nodes || item.nodes.length === 0) return;
      item.nodes.map((node: any) => {
        sourceData.push({
          ...node,
          pipelineId: item.pipelineId,
        });
      });
    });

    setPipelineDataSource(PipelineSource(sourceData));
  }, [pipelineData]);

  useEffect(() => {
    if (!aisVesselLocationData) return;
    if (!!isAisRegionVesselColorsLoading) return;
    if (!aisRegionVesselColors) return;

    let itemMap = new Map();

    aisVesselLocationData.forEach((item: any) => {
      itemMap.set(String(item.mmsi), { ...item });
    });

    aisRegionVesselColors.forEach((item: any) => {
      const existingItem = itemMap.get(item.mmsi);
      if (existingItem) {
        existingItem.color = item.color;
      }
    });

    const updatedAisRegionVesselSource = Array.from(itemMap.values());
    setAISRegionVesselSource(
      AISRegionVesselSource(updatedAisRegionVesselSource)
    );
  }, [
    aisVesselLocationData,
    aisRegionVesselColors,
    isAisRegionVesselColorsLoading,
  ]);

  useEffect(() => {
    if (assetHistory && assetHistory.length > 0) {
      const data = assetHistory?.map((row: any) => ({
        position: {
          lon: row.lon,
          lat: row.lat,
        },
      }));
      setAssetTripHistorySource(LineStringSource(data));
    }
  }, [assetHistory]);

  const resetActiveLocation = useCallback(() => {
    if (!locationsDataSource) return;

    const newLoc = { ...locationsDataSource };

    newLoc?.data?.data?.features?.forEach((item: any) => {
      if (item.properties.active) {
        item.properties.active = false;
      }
    });
    setLocationsDataSource(newLoc);
  }, [locationsDataSource, setLocationsDataSource]);

  useEffect(() => {
    if (!activeMapFeature?.asset) return;
    if (!assetDetails) return;
    if (assetLocationData.length === 0) return;

    const { position } = assetDetails;
    if (!position) return;
    const { lon, lat } = position;
    if (!lon || !lat) return;
    const center = centerDict({
      coordinates: [lon, lat],
      type: 'Point',
    });
    setPopup({
      el: AssetPopup(assetDetails, setPopup, navigate),
      lngLat: center,
      anchor: 'top',
      shouldFlyTo: {
        zoom: activeMapFeature?.zoomLevel || mapZoom,
        offset: activeMapFeature?.offset,
      },
      external: !!activeMapFeature?.external,
    });
  }, [assetLocationData, activeMapFeature, assetDetails]);

  useEffect(() => {
    if (!activeMapFeature?.asset) return;
    if (!assetDetails) return;

    const { position } = assetDetails;
    if (!position) return;
    const { lon, lat } = position;
    if (!lon || !lat) return;
    const center = centerDict({
      coordinates: [lon, lat],
      type: 'Point',
    });
    setPopup({
      // id: assetDetails.assetId,
      el: AssetPopup(assetDetails, setPopup, navigate),
      lngLat: center,
      anchor: 'top',
      shouldFlyTo: {
        zoom: activeMapFeature?.zoomLevel || mapZoom,
        offset: activeMapFeature?.offset,
      },
      external: !!activeMapFeature?.external,
    });

    resetActiveLocation();
  }, [activeMapFeature, assetDetails]);

  useEffect(() => {
    if (!activeMapFeature?.location) return;
    if (!locationDetails) return;

    const { position } = locationDetails;
    if (!position) return;
    const { lon, lat } = position;
    if (!lon || !lat) return;
    let featureBox;
    if (locationDetails.feature) {
      featureBox = bbox(locationDetails.feature);
    }
    const center = centerDict({
      coordinates: [lon, lat],
      type: 'Point',
    });
    setPopup({
      // id: locationDetails.locationId,
      el: LocationPopup(locationDetails, onClickListAssets, setPopup),
      lngLat: center,
      anchor: 'top',
      featureBox,
      isMobile,
      shouldFlyTo: {
        zoom: mapZoom,
        offset: activeMapFeature?.offset,
      },
      external: !!activeMapFeature?.external,
    });

    if (locationsDataSource) {
      let newLoc = { ...locationsDataSource };
      newLoc?.data?.data?.features?.forEach((item: any) => {
        if (
          item?.properties?.id === activeMapFeature?.location &&
          item?.geometry?.type === 'Polygon'
        ) {
          item.properties.active = true;
        } else if (item.properties.active) {
          item.properties.active = false;
        }
      });
      setLocationsDataSource(newLoc);
    }
    setActiveMapFeature(null);
  }, [activeMapFeature, locationDetails]);

  useEffect(() => {
    if (!activeMapFeature?.mmsi) return;
    if (!aisRegionVesselDetails) return;

    const mmsiColor =
      aisRegionVesselColors?.find(
        (item: any) => item.mmsi === String(aisRegionVesselDetails.mmsi)
      ) || null;

    const { position } = aisRegionVesselDetails;
    if (!position) return;
    const { location } = position;
    if (!location) return;
    const { long, lat } = location;
    if (!long || !lat) return;
    const center = centerDict({
      coordinates: [long, lat],
      type: 'Point',
    });
    setPopup({
      el: AISRegionVesselPopup(
        {
          ...aisRegionVesselDetails,
          ...(mmsiColor && { color: mmsiColor.color }),
        },
        upsertAisRegionVesselColor
      ),
      lngLat: center,
      anchor: 'top',
      shouldFlyTo: {
        zoom: mapZoom,
        offset: activeMapFeature?.offset,
      },
      external: !!activeMapFeature?.external,
    });

    resetActiveLocation();
  }, [activeMapFeature, aisRegionVesselDetails]);

  useEffect(() => {
    const newZoom = Math.max(mapZoom, 6);
    if (mainMap.flyTo) {
      setMapZoom(newZoom);
      setFlyTo({
        center: mainMap.flyTo,
        zoom: newZoom,
        essential: true,
        offset: isMobile ? [0, -(window.innerWidth / 4)] : [0, 0],
      });
    }
  }, [mainMap.flyTo]);

  // useEffect(() => {
  // }, [mapZoom]);

  const activateAsset = useCallback(
    (
      id: number,
      shouldRequest: boolean = true,
      flyTo: boolean = false,
      external: boolean = false,
      zoomLevel?: number
    ) => {
      popupIdRef.current = id;
      if (shouldRequest)
        setActiveMapFeature({
          asset: id,
          flyTo,
          external,
          zoomLevel,
          offset: isMobile ? [0, -(window.innerWidth / 4)] : [0, 0],
        });
      setMainMapField('activeAssetId', null);
    },
    [setMainMapField, setActiveMapFeature]
  );

  const activateLocation = useCallback(
    (id: number, request: boolean = true, external: boolean = false) => {
      popupIdRef.current = id;
      if (request)
        setActiveMapFeature({
          location: id,
          flyTo: true,
          external,
          offset: isMobile ? [0, -(window.innerWidth / 4)] : [0, 0],
        });
      setMainMapField('activeLocationId', null);
    },
    [setMainMapField, setActiveMapFeature]
  );

  const activateAISRegionVessel = useCallback(
    (
      id: number,
      shouldRequest: boolean = true,
      flyTo: boolean = false,
      external: boolean = false
    ) => {
      popupIdRef.current = id;
      if (shouldRequest) setActiveMapFeature({ mmsi: id, flyTo, external });
      setMainMapField('activeAssetId', null);
    },
    [setMainMapField, setActiveMapFeature]
  );

  const onClickListAssets = () => {
    setActive(ActionMenuModuleTypes.ListAssets, {
      locationName: locationDetails.name,
      locationId: locationDetails.locationId,
    });
    openRightSidebar();
  };

  useEffect(() => {
    if (mainMap.activeAssetId) {
      activateAsset(mainMap.activeAssetId, true, true, true);
    }
  }, [mainMap.activeAssetId]);

  useEffect(() => {
    if (mainMap.activeLocationId) {
      activateLocation(mainMap.activeLocationId, true, true);
    }
  }, [mainMap.activeLocationId]);

  const isFeaturePoint = (feature: any) => {
    return feature && feature.geometry && feature.geometry.type === 'Point';
  };

  const centerDict = ({
    coordinates,
    e,
    type,
  }: {
    coordinates?: any;
    e?: any;
    type: string;
  }): LngLat | null => {
    if (type === 'Point') {
      if (!coordinates || !coordinates[0] || !coordinates[1]) return null;
      return new LngLat(coordinates[0], coordinates[1]);
    }
    if (type == 'Polygon') {
      return new LngLat(e.lngLat.lng, e.lngLat.lat);
    }

    return null;
  };

  const preventSameLayerTypeRender = (layer: any): boolean => {
    // if (
    //   ASSET_LAYERS.includes(layer.id) &&
    //   ASSET_LAYERS.includes(popupLayerRef.current.layer)
    // ) {
    //   return true;
    // }
    // if (
    //   LOCATION_LAYERS.includes(layer.id) &&
    //   LOCATION_LAYERS.includes(popupLayerRef.current.layer)
    // ) {
    //   return true;
    // }
    if (
      CLUSTER_LAYERS.includes(layer.id) &&
      CLUSTER_LAYERS.includes(popupLayerRef.current.layer)
    ) {
      return true;
    }
    return false;
  };

  const onClick = (e: any, map: mapboxgl.Map) => {
    const { features } = e;
    if (!features || !features[0] || !features[0].geometry) return;
    const preferredFeature = features[0];
    const { geometry, properties, layer } = preferredFeature;
    const { coordinates, type } = geometry;
    const center = centerDict({ coordinates, e, type });
    if (center && layer && isLayerClickable(layer.id)) {
      const idProperty = getIdPropertyForLayer(layer.id);
      const id = properties[idProperty];
      if ('mmsi' === idProperty) {
        activateAISRegionVessel(id);
      } else if (CLUSTER_LAYERS.includes(layer.id)) {
        if (map) {
          const cluster = preferredFeature.properties.cluster_id;
          const points = preferredFeature.properties.point_count;
          const source = map?.getSource(layer.source) as GeoJSONSource;
          source.getClusterLeaves(
            cluster,
            points,
            0,
            (err: any, clusterFeatures: any) => {
              setPopup({
                el: ClusterPopup({
                  features: clusterFeatures,
                  onClick: activateAsset,
                }),
                lngLat: center,
                anchor: 'top',
                shouldFlyTo: false,
                hover: true,
              });
            }
          );
        }
      } else if (ASSET_LAYERS.includes(layer.id)) {
        activateAsset(id);
      } else if (LOCATION_LAYERS.includes(layer.id)) {
        activateLocation(id);
      }
    }
  };

  const onHover = useCallback(
    (e: any, map: mapboxgl.Map) => {
      const feature = e.features[0];
      if (!feature) return;
      const { geometry, properties, layer } = feature;
      const { coordinates, type } = geometry;
      const center = centerDict({ coordinates, e, type });

      // prevent re-rendering of same layer type (for some)
      if (preventSameLayerTypeRender(layer.id)) {
        popupLayerRef.current.layer = layer.id;
        return;
      }

      popupLayerRef.current.layer = layer.id;
      if (center && layer && isLayerClickable(layer.id)) {
        const id = properties[getIdPropertyForLayer(layer.id)];
        if (ASSET_LAYERS.includes(layer.id)) {
          if (
            [
              LAYERS.ASSET_POINTS,
              LAYERS.VESSEL_LOCATIONS,
              LAYERS.VESSEL_LOCATIONS_AIS,
              LAYERS.ASSET_HEADINGS,
              LAYERS.AIS_REGION_VESSEL_LOCATION,
              LAYERS.STATIC_ASSET_SECURITY_CAMERAS,
            ].includes(layer.id) &&
            showAssetLabelsRef.current
          ) {
            return;
          }
          setPopup({
            el: HoverPopup(properties),
            lngLat: center,
            anchor: 'top',
            shouldFlyTo: false,
            hover: true,
          });
          activateAsset(properties.id, false);
        } else if (LOCATION_LAYERS.includes(layer.id)) {
          setPopup({
            el: HoverPopup(properties),
            lngLat: center,
            anchor: 'top',
            shouldFlyTo: false,
            hover: true,
          });
          activateLocation(properties.id, false);
        } else if (CLUSTER_LAYERS.includes(layer.id)) {
          if (map) {
            const cluster = properties.cluster_id;
            const points = properties.point_count;
            const source = map?.getSource(layer.source) as GeoJSONSource;
            source.getClusterLeaves(
              cluster,
              points,
              0,
              (err: any, clusterFeatures: any) => {
                setPopup({
                  el: ClusterPopup({ features: clusterFeatures, limit: 5 }),
                  lngLat: center,
                  anchor: 'top',
                  shouldFlyTo: false,
                  hover: true,
                });
              }
            );
          }
        }
      }
    },
    [setPopup, activateAsset, activateLocation, showAssetLabels]
  );

  const onRightClick = (e: any, handlers: any) => {
    const { features } = e;

    const type = (() => {
      if (features?.[0]?.properties) {
        const properties = features?.[0]?.properties;
        if (properties?.assetId) return 'asset';
        if (properties?.locationId) return 'location';
      }
      return null;
    })();

    handlers.copyCoordinates = () => {
      navigator.clipboard.writeText(
        `${e.lngLat.lat.toFixed(8)}, ${e.lngLat.lng.toFixed(8)}`
      );
      setContextMenu(null);
      setFlashMessage({
        message: i18n.t('Copied to clipboard'),
        type: 'success',
      });
    };

    if (
      features &&
      features[0] &&
      (['locations', 'bounds'].includes(features[0]?.layer?.id) ||
        ['locations', 'bounds'].includes(features[1]?.layer?.id))
    ) {
      handlers.editLocation = () => {
        setActive(ActionMenuModuleTypes.EditLocation, {
          locationId: features[0].properties?.locationId,
        });
        openRightSidebar();
        setContextMenu(null);
      };
      handlers.deleteLocation = () => {
        setDeleteLocation({
          locationId: features[0].properties?.locationId,
          name: features[0].properties?.name,
        });
        setContextMenu(null);
      };
    }
    if (
      (features &&
        features[0] &&
        LAYERS.STATIC_ASSET_PIPELINE_LINE_LAYER === features[0]?.layer?.id) ||
      LAYERS.STATIC_ASSET_PIPELINE_LINE_LAYER === features[1]?.layer?.id
    ) {
      const { properties } = features.find(
        (f: any) => f.layer.id === LAYERS.STATIC_ASSET_PIPELINE_LINE_LAYER
      );
      handlers.deletePipeline = () => {
        setDeletePipeline({
          fromAssetId: properties.fromAssetId,
          toAssetId: properties.toAssetId,
        });
        setContextMenu(null);
      };
    }
    if (
      (features &&
        features[0] &&
        LAYERS.ASSET_POINTS === features[0]?.layer?.id) ||
      LAYERS.ASSET_POINTS === features[1]?.layer?.id
    ) {
      if (
        deviceHasCapability(
          features[0].properties?.assetDevices,
          'request_location'
        )
      ) {
        handlers.requestAssetLocation = () => {
          requestLocation(features[0].properties?.assetId);
          setContextMenu(null);
        };
      }
      if (deviceHasCapability(features[0].properties?.assetDevices, 'reboot')) {
        handlers.requestReboot = () => {
          setRebootDevice({
            assetId: features[0].properties?.assetId,
            assetName: features[0].properties?.name,
          });
          setContextMenu(null);
        };
      }
      if (features[0].properties?.hasIgnitionLockout) {
        handlers.ignitionLockout = {
          lastRequestedAt: features[0].properties?.ignitionLockoutRequestedAt,
          lastRequestedState:
            features[0].properties?.ignitionLockoutRequestedState,
          currentState: features[0].properties?.stateIgnitionLockedOut,
          requestHandler: (lockoutState = true) => {
            if (!features[0].properties?.stateIgnitionLockedOut) {
              setLockoutDevice({
                assetId: features[0].properties?.assetId,
                stateIgnitionLockedOut:
                  !features[0].properties?.stateIgnitionLockedOut,
                name: features[0].properties?.name,
              });
            } else {
              requestIgnitionLockout(features[0].properties?.assetId, false);
            }
          },
        };
      }
    }
    const feature = features.find(
      (f: any) => f.layer.id === LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION
    );
    if (!!feature) {
      const { geometry, properties } = feature;
      const { coordinates } = geometry;
      handlers.connectUpstream = () => {
        isPipelineMode.current = properties.assetId;
        setIsPipelineModeOn(true);
        setMainMapField('drawMode', 'draw_pipeline');
        setDrawModeOptions({
          startingPoint: coordinates,
          startProperties: properties,
        });
        setStaticAssetPipelineConnectorBoundsSource(
          StaticAssetPipelineConnectorSource(
            pipelineConnectorBounds.current?.filter(
              (a: any) => a.assetId !== properties.assetId
            )
          )
        );
        setContextMenu(null);
      };
    }
    setContextMenu({
      el: ContextMenuPopup(handlers, canWriteRef.current, type),
      lngLat: e.lngLat,
    });
  };

  const deviceHasCapability = (assetDevices: any, capability: string) => {
    const devices =
      typeof assetDevices === 'string'
        ? JSON.parse(assetDevices)
        : assetDevices;
    return deviceTypes.deviceTypes
      .find((type: any) => type.id === devices[0].deviceType)
      ?.capabilities?.includes(capability);
  };

  const updateMapZoomLevel = (zoomLevel: number) => {
    if (!mainMap.flyTo) {
      setMapZoom(zoomLevel);
    }
  };

  useEffect(() => {
    if (!!resetDrawnFeatures) {
    }
  }, [resetDrawnFeatures]);

  const onDraw = useCallback(
    (type: string, features: any, allFeatures: any, mode: string) => {
      if ('draw_pipeline' === mode) {
        if (features.length === 0) return;
        linkStaticAssets({
          assetFrom: features[0].properties.assetFrom,
          assetTo: features[0].properties.assetTo,
        });
        resetPipelineDraw();
        return;
      }
      setDrawnFeatures(allFeatures);
      if ('delete' === type) {
        setPopup(null);
        if (isFeaturePoint(features[0])) {
          reset();
          closeRightSidebar();
        }
        updateAddLocationData(allFeatures.features);
      }
      if (['create', 'move'].includes(type)) {
        features = allFeatures.features;
        updateAddLocationData(features);
        if (isFeaturePoint(features[0])) {
          activateAddLocation();
          openRightSidebar();
          // if (features[1] && features[1].geometry.type === 'Polygon') {
          //   setBounds(
          //     bbox(
          //       buffer(features[1].geometry, 10, {
          //         units: 'kilometers',
          //       })
          //     )
          //   );
          // }
        }
      }
    },
    [
      activateAddLocation,
      closeRightSidebar,
      openRightSidebar,
      reset,
      setAddLocationData,
    ]
  );

  // takes in features, figures out which one is point and which one is bounds
  // if both are present, it will set the bounds
  // if only one, which is point, is present, it will set the point and null the bounds
  // if only one, which is bounds, is present, it will set empty object
  const updateAddLocationData = (features: any) => {
    if (!features) return;
    if (features.length === 0) return;
    const point = features.find((f: any) => f.geometry.type === 'Point');
    const bounds = features.find((f: any) => f.geometry.type === 'Polygon');
    if (point || bounds) {
      setAddLocationData({
        ...(point && {
          position: {
            lon: point?.geometry?.coordinates?.[0],
            lat: point?.geometry?.coordinates?.[1],
          },
        }),
        ...(bounds && {
          bounds: {
            type: bounds?.geometry?.type,
            coordinates: bounds?.geometry?.coordinates,
          },
        }),
      });
    }
  };

  const resetPipelineDraw = () => {
    setDrawModeOptions(null);
    setDrawnFeatures(null);
    setMainMapField('drawMode', 'simple_select');
    isPipelineMode.current = null;
    setIsPipelineModeOn(false);
  };

  const dashArraySequence = [
    [0, 4, 3],
    [0.5, 4, 2.5],
    [1, 4, 2],
    [1.5, 4, 1.5],
    [2, 4, 1],
    [2.5, 4, 0.5],
    [3, 4, 0],
    [0, 0.5, 3, 3.5],
    [0, 1, 3, 3],
    [0, 1.5, 3, 2.5],
    [0, 2, 3, 2],
    [0, 2.5, 3, 1.5],
    [0, 3, 3, 1],
    [0, 3.5, 3, 0.5],
  ];

  const animateProperty = (timestamp: any) => {
    if (!mapRef.current) return;
    if (!mapRef.current.getLayer) return;
    if (!mapRef.current.setPaintProperty) return;

    animating.current = true;

    const newStep = Math.floor((timestamp / 50) % dashArraySequence.length);

    if (
      newStep !== currentStep.current &&
      mapRef.current?.getLayer('static-asset-pipeline-line-animation-layer')
    ) {
      mapRef.current?.setPaintProperty(
        'static-asset-pipeline-line-animation-layer',
        'line-dasharray',
        dashArraySequence[newStep]
      );
      currentStep.current = newStep;
    }

    requestAnimationFrame(animateProperty);
  };

  const updateMapStyleSetting = (style: string) => {
    if (!style) return;
    const mapStyleObject = Object.entries(MapStyles).find(
      (s: any) => s[1] === style
    );
    if (!mapStyleObject) return;
    updateUserSetting({
      mapStyle: mapStyleObject[0],
    });
  };

  useEffect(() => {
    if (!userData) return;
    if (showAssetLabels === null) return;
    if (userData?.settings?.showAssetLabels === showAssetLabels) return;
    if (!userData?.settings?.showAssetLabels && !showAssetLabels) {
      return;
    }
    updateUserSetting({
      showAssetLabels,
    });
  }, [showAssetLabels]);

  useEffect(() => {
    if (!userData) return;
    if (!userData?.settings) return;
    if (!userData?.settings?.showAssetLabels) return;

    setShowAssetLabels(userData?.settings?.showAssetLabels);
    showAssetLabelsRef.current = userData?.settings?.showAssetLabels;
  }, [userData?.settings?.showAssetLabels]);

  if (!animating.current) {
    animateProperty(0);
  }

  if (!rightSideBarWidth) return <></>;

  const CustomControls = (map: mapboxgl.Map) => {
    if (!map) return;
    const headingValue = (-45 + map.getBearing()).toFixed(0);
    return (
      <div
        className='mapboxgl-control-custom-controls'
        style={{
          right: isMobile ? '10px' : `calc( ${rightSideBarWidth}px + 15px )`,
          ...(isMobile && {
            position: 'fixed',
            bottom: '10dvh',
          }),
          ...(!isMobile && { bottom: 30 }),
        }}>
        {!isMobile && (
          <>
            <div>
              <Tooltip
                title='Add a new location'
                arrow
                placement='left'
                disableInteractive>
                <div
                  onClick={() => setMainMapField('drawMode', 'draw_point')}
                  className='control-button'>
                  <EditLocationAltTwoTone
                    fontSize='medium'
                    fill={false ? 'rgba(127, 49, 49, 1)' : 'white'}
                  />
                </div>
              </Tooltip>
            </div>
            <hr className='mapboxgl-controls-custom-divider' />
          </>
        )}
        <div>
          <Tooltip title='Zoom in' arrow placement='left' disableInteractive>
            <div
              onMouseDown={() => (isMobile ? null : map.zoomIn(ZOOM_OPTIONS))}
              onTouchEnd={() => (isMobile ? map.zoomIn(ZOOM_OPTIONS) : null)}
              className='control-button'>
              <ZoomIn fontSize='medium' fill='white' />
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip title='Zoom out' arrow placement='left' disableInteractive>
            <div
              onMouseDown={() => (isMobile ? null : map.zoomOut(ZOOM_OPTIONS))}
              onTouchEnd={() => (isMobile ? map.zoomOut(ZOOM_OPTIONS) : null)}
              className='control-button'>
              <ZoomOut fontSize='medium' fill='white' />
            </div>
          </Tooltip>
        </div>
        <div>
          <Tooltip
            title='Change Map style'
            arrow
            placement='left'
            disableInteractive>
            <div
              onMouseDown={() =>
                isMobile
                  ? null
                  : setShowStyleSelectorMenu(!showStyleSelectorMenu)
              }
              onTouchEnd={() =>
                isMobile
                  ? setShowStyleSelectorMenu(!showStyleSelectorMenu)
                  : null
              }
              className='control-button'>
              <LayersTwoTone fontSize='medium' fill='white' />
            </div>
          </Tooltip>
        </div>
        {!isMobile && (
          <div>
            <Tooltip
              title='Change Map projection'
              arrow
              placement='left'
              disableInteractive>
              <div
                onMouseDown={() =>
                  storeMapProjection(
                    storedMapProjection === 'mercator' ? 'globe' : 'mercator'
                  )
                }
                className='control-button'>
                {storedMapProjection === 'mercator' ? (
                  <PublicTwoTone fontSize='medium' fill='white' />
                ) : (
                  <MapTwoTone fontSize='medium' fill='white' />
                )}
              </div>
            </Tooltip>
          </div>
        )}
        {!!showStyleSelectorMenu && (
          <StyleSelectorControl
            onMouseOut={() => setShowStyleSelectorMenu(false)}
            outerActiveStyle={
              storedMapStyle || 'mapbox://styles/mapbox/dark-v11?optimize=true'
            }
            onClick={(style: string) => {
              storeMapStyle(style);
              setShowStyleSelectorMenu(false);
            }}
            direction={direction}
          />
        )}
        <div>
          <Tooltip
            title={showAssetLabels ? 'Hide asset labels' : 'Show asset labels'}
            arrow
            disableInteractive
            placement='left'>
            <div
              onMouseDown={() => {
                if (isMobile) return;
                setShowAssetLabels(!showAssetLabels);
                showAssetLabelsRef.current = !showAssetLabelsRef.current;
              }}
              onTouchEnd={() => {
                if (!isMobile) return;
                setShowAssetLabels(!showAssetLabels);
                showAssetLabelsRef.current = !showAssetLabelsRef.current;
              }}
              className='control-button'>
              {showAssetLabels && (
                <LabelOffTwoTone fontSize='medium' fill='white' />
              )}
              {!showAssetLabels && (
                <LabelTwoTone fontSize='medium' fill='white' />
              )}
            </div>
          </Tooltip>
        </div>
        {!isMobile && (
          <div>
            <Tooltip
              title={
                stored3DBuildings ? 'Hide 3D buildings' : 'Show 3D buildings'
              }
              arrow
              disableInteractive
              placement='left'>
              <div
                onMouseDown={() => {
                  store3DBuildings(!stored3DBuildings);
                }}
                className='control-button'>
                {stored3DBuildings && (
                  <DomainDisabledTwoTone fontSize='medium' fill='white' />
                )}
                {!stored3DBuildings && (
                  <ApartmentTwoTone fontSize='medium' fill='white' />
                )}
              </div>
            </Tooltip>
          </div>
        )}
        {!isMobile && MapStyles?.maritime === storedMapStyle && (
          <div>
            <Tooltip
              title={
                showShippingLanes
                  ? 'Hide shipping lanes'
                  : 'Show shipping lanes'
              }
              arrow
              disableInteractive
              placement='left'>
              <div
                onMouseDown={() => {
                  setShowShippingLanes(!showShippingLanes);
                }}
                className='control-button'>
                {showShippingLanes && (
                  <SailingTwoTone
                    fontSize='medium'
                    fill='white'
                    sx={{ color: 'rgba(255,255,255,0.2);' }}
                  />
                )}
                {!showShippingLanes && (
                  <SailingTwoTone fontSize='medium' fill='white' />
                )}
              </div>
            </Tooltip>
          </div>
        )}
        {(map.getPitch() !== 0 || map.getBearing() !== 0) && (
          <div>
            <div
              onClick={() => {
                map.setBearing(0);
                map.setPitch(0);
              }}
              className='control-button'>
              <ExploreTwoTone
                fontSize='medium'
                fill='white'
                sx={{ transform: `rotate(${headingValue}deg)` }}
              />
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <ConfirmDeleteDialog
        open={!!deleteLocation}
        onCancel={() => {
          setDeleteLocation(null);
        }}
        onConfirm={() => {
          if (deleteLocation)
            deleteLocationRequest({ id: deleteLocation?.locationId });
        }}
        title={'Confirm to delete location'}
        content={
          <>
            Are you sure you want to delete <b>{deleteLocation?.name}</b>?
          </>
        }
      />
      <ConfirmDeleteDialog
        open={!!deletePipeline}
        onCancel={() => {
          setDeletePipeline(null);
        }}
        onConfirm={() => {
          if (deletePipeline)
            unlinkStaticAssets({
              assetFrom: deletePipeline?.fromAssetId,
              assetTo: deletePipeline?.toAssetId,
            });
        }}
        title={'Confirm to delete pipeline'}
        content={<>Are you sure you want to delete this pipeline?</>}
      />
      <ConfirmDeleteDialog
        deleteText={'engage'}
        confirmButtonColor='#ffbf00'
        icon={<Lock sx={{ color: '#ffbf00 !important', mr: 1 }} />}
        open={!!lockoutDevice}
        onCancel={() => {
          setLockoutDevice(null);
        }}
        onConfirm={() => {
          if (!!lockoutDevice) {
            requestIgnitionLockout(
              lockoutDevice?.assetId,
              !lockoutDevice?.stateIgnitionLockedOut
            );
            setContextMenu(null);
          }
        }}
        title={t('Confirm to engage lockout')}
        content={t(
          'Are you sure you want to engage remote lockout for {{name}}?',
          { name: lockoutDevice?.name }
        )}
      />
      <ConfirmDeleteDialog
        deleteText={'reboot'}
        icon={<RestartAlt sx={{ mr: 1 }} color='error' />}
        open={!!rebootDevice}
        onCancel={() => {
          setRebootDevice(null);
        }}
        onConfirm={() => {
          if (rebootDevice) requestReboot(rebootDevice?.assetId);
        }}
        title={'Confirm to reboot device'}
        content={
          <>
            Are you sure you want to reboot <b>{rebootDevice?.assetName}</b>?
          </>
        }
      />
      <AltMap
        mapRef={mapRef}
        direction={direction}
        key={location.pathname}
        projection={isMobile ? 'mercator' : storedMapProjection}
        customContainerClass='map-container-left-margin'
        center={initialMapCenter}
        zoom={initialMapZoom}
        onDraw={onDraw}
        onClick={onClick}
        onClickAnywhere={(e: any, m: any) => {
          const { features } = e;
          const f = features?.find((f: any) => 'assetLocations' === f.source);
          if (!f && tripHistoryDiscardable.current) {
            setAssetTripHistorySource(LineStringSource([]));
          }
          tripHistoryDiscardable.current = !f;
        }}
        onHover={onHover}
        onRightClick={(e: any, handlers: any) => onRightClick(e, handlers)}
        popup={popup}
        onPopupClose={(e: any) => {
          popupLayerRef.current.layer = null;
          setPopup(null);
          // setAssetTripHistorySource(null);
          resetActiveLocation();
          setActiveMapFeature(null);
          setMainMapField('activeAssetId', null);
          setMainMapField('activeLocationId', null);
          setFlyTo(null);
        }}
        onDrawModeExited={() => {
          resetPipelineDraw();
          reset();
        }}
        onZoomChanged={updateMapZoomLevel}
        onCenterChanged={setMapCenter}
        sources={getSources()}
        layers={getLayers()}
        clickableLayers={[
          LAYERS.ASSET_POINTS,
          LAYERS.LOCATIONS,
          LAYERS.VESSEL_LOCATIONS,
          LAYERS.VESSEL_LOCATIONS_AIS,
          LAYERS.AIS_REGION_VESSEL_LOCATION,
          LAYERS.STATIC_ASSET_WELLHEAD_LOCATION,
          LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION,
          LAYERS.STATIC_ASSET_SECURITY_CAMERAS,
          LAYERS.ASSET_CLUSTERS,
          LAYERS.ASSET_CLUSTERS_COUNT,
        ]}
        hoverableLayers={[
          LAYERS.ASSET_POINTS,
          LAYERS.LOCATIONS,
          LAYERS.VESSEL_LOCATIONS,
          LAYERS.VESSEL_LOCATIONS_AIS,
          LAYERS.AIS_REGION_VESSEL_LOCATION,
          LAYERS.STATIC_ASSET_WELLHEAD_LOCATION,
          LAYERS.STATIC_ASSET_PIPEMONITOR_LOCATION,
          LAYERS.STATIC_ASSET_PIPELINE_LINE_LAYER,
          LAYERS.STATIC_ASSET_SECURITY_CAMERAS,
          LAYERS.ASSET_CLUSTERS,
          LAYERS.ASSET_CLUSTERS_COUNT,
        ]}
        images={[
          vesselAisImage,
          assetHeadingsImage,
          assetHeadingsImagePanic,
          locationImage,
          vesselImage,
          vesselImagePanic,
          aisRegionVesselImage,
          aisRegionVesselImagePurple,
          aisRegionVesselImageOrange,
          aisRegionVesselImagePink,
          aisRegionVesselImageTeal,
          aisRegionVesselImageYellow,
          staticAssetWellheadImage,
          staticAssetPipeMonitorImage,
          assetLabelImage,
          securityCameraFovImage,
          // assetLabelIgnitionImage,
          // assetLabelIdlingImage,
        ]}
        flyTo={flyTo}
        resetDrawnFeatures={mainMap?.resetDrawnFeatures}
        bounds={bounds}
        drawMode={mainMap?.drawMode}
        drawModeExtra={drawModeOptions}
        contextMenu={contextMenu}
        onDrawModeChanged={(mode: string) => setDrawMode(mode)}
        lang={i18n.resolvedLanguage}
        CustomControls={CustomControls}
        controlStyles={{
          ...('rtl' === direction
            ? {
                bottomLeft: `margin-left: ${
                  rightSideBarWidth > 65 ? rightSideBarWidth : 0
                }px; left: 16px; display: flex;`,
              }
            : {
                bottomRight: `margin-right: ${rightSideBarWidth}px; right: 16px; display: flex;`,
              }),
        }}
        mapStyle={
          storedMapStyle || 'mapbox://styles/mapbox/dark-v11?optimize=true'
        }
        onStyleChange={updateMapStyleSetting}
        show3D={false}
        drawCustomLayerStyle={CustomDrawLayerStyle}
      />
    </>
  );
};

export default MapView;
