import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import logo from 'assets/logo.png';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderEl = styled('div')`
  z-index: 99999;
  height: 60px;
  width: 60px;

  img {
    height: 100%;
    width: 100%;
    animation: ${rotate} 0.66s infinite steps(6);
    -webkit-animation: ${rotate} 0.66s infinite steps(6);
  }
`;

function InlineLoader() {
  return (
    <LoaderEl>
      <img src={logo} alt='Loading' />
    </LoaderEl>
  );
}

export default InlineLoader;
