import useUIContext from 'context/UIContext';
import {
  useActivityLog,
  useCurrentUser,
  useCurrentUserOnboarding,
  useUpdateCurrentUserOnboarding,
} from 'hooks';
import useDebounce from 'hooks/useDebounce';
import React, { useEffect, useState } from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import activitySampleData from './activity_sample_data.json';
import moment from 'moment';

const steps = [
  {
    target: '.main-nav-item-map',
    title: 'Map',
    content: (
      <>
        The map page is where you view all your assets geographic location,
        interact with your assets, add your locations, and more. You can{' '}
        <b>right-click</b> on the map or your assets to view the interactions.
      </>
    ),
    placement: 'auto',
    disableBeacon: true,
  },
  {
    target: '.main-nav-item-reports',
    title: 'Reports',
    content: (
      <>
        The reports section is where you can view all the historical data and
        analytics regarding your assets, as well as download and export these
        reports.
      </>
    ),
    placement: 'auto',
    disableBeacon: true,
    event: 'navigate:reports',
  },
  // {
  //   target: '.reports-location-activity',
  //   title: 'Reports',
  //   content: (
  //     <>
  //       The reports available are dependant on the asset types you have added,
  //       as you add new asset types, new reports will become available.
  //     </>
  //   ),
  //   placement: 'auto',
  //   disableBeacon: true,
  //   event: 'navigate:reports',
  // },
  // {
  //   target: '.main-nav-item-vehicle-dashboard',
  //   title: 'Vehicle Dashboard',
  //   content:
  //     'Dashboards give you a high-level overview for each of your assets. They are also interactive so clicking fields within the dashboards can take you do different pre-filled reports.',
  //   placement: 'auto',
  //   disableBeacon: true,
  //   event: 'navigate:vehicle-dashboard',
  // },
  // {
  //   target: '.main-nav-item-ais-vessel-dashboard',
  //   title: 'AIS Vessel Dashboard',
  //   content:
  //     'Dashboards give you a high-level overview for each of your assets. They are also interactive so clicking fields within the dashboards can take you do different pre-filled reports.',
  //   placement: 'auto',
  //   disableBeacon: true,
  //   event: 'navigate:vessel_ais-dashboard',
  // },
  {
    target: '.main-nav-item-settings',
    title: 'Settings',
    content:
      'The settings section is where you will perform all of your platform administration such as adding, editing or deleting users, assets, locations; as well as other platform settings such as 3rd party integrations, driver analytics preferences, and more...',
    placement: 'left-end',
    disableBeacon: true,
    event: 'navigate:settings',
  },
  {
    target: '.right-side-bar-hack',
    content:
      "The Activity Feed is a live feed of all your asset's activities such as entering or exiting locations, any alarms they trigger, text messages, and more. There is also a global search function at the top which gives you quick access to your assets, locations, and their activities.",
    event: 'navigate:map,open:activity-feed',
    placement: 'left',
    disableBeacon: true,
  },
];

const style = {
  zIndex: 99999,
  backgroundColor: 'rgba(0,0,0,0.7)',
  arrowColor: 'rgba(255,255,255,0.5)',
  textColor: 'white',
};

export const Onboarding = ({ user }: { user: any }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: activityData } = useActivityLog({});

  const { data, isLoading } = useCurrentUser() as any;
  const { openRightSidebar, fixRightSidebar, closeRightSidebar } =
    useUIContext();
  const [ready, setReady] = useState(false);
  const { mutate: updateOnboarding } = useUpdateCurrentUserOnboarding();

  useEffect(() => {
    if (data && !data?.onboardedAt) {
      if (data.hasVehicles) {
        steps.splice(4, 1);
      } else if (data.hasAISVessels) {
        steps.splice(3, 1);
      }
      navigate('/');
      setTimeout(() => setReady(true), 500);
      closeRightSidebar();
      fixRightSidebar(false);
    }
  }, [data]);

  const resetActivityFeed = () => {
    // queryClient.setQueryData(['activity', {}], resetActivitySampleData());
    fixRightSidebar(false);
    closeRightSidebar();
    setTimeout(() => {
      queryClient.removeQueries('activity');
    }, 0);
  };

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type, step } = data;
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
    } else if ([STATUS.FINISHED].includes(status)) {
      resetActivityFeed();
      updateOnboarding({ isCompleted: true, currentStep: index });
      navigate('/');
      return;
    } else if ([STATUS.SKIPPED].includes(status)) {
      resetActivityFeed();
      updateOnboarding({ isCompleted: true, currentStep: index });
      navigate('/');
      return;
      // Need to set our running state to false, so we can restart if we click start again.
    }

    if (!!step && step.event) {
      if (step.event === 'navigate:reports') {
        navigate('/reports');
      }
      if (step.event === 'navigate:vehicle-dashboard') {
        navigate('/dashboard/vehicles');
      }
      if (step.event === 'navigate:vessel_ais-dashboard') {
        navigate('/dashboard/vessels_ais');
      }
      if (step.event === 'navigate:settings') {
        navigate('/settings');
      }
      if (step.event === 'navigate:map,open:activity-feed') {
        navigate('/');
        setTimeout(() => {
          openRightSidebar();
          fixRightSidebar(true);
          queryClient.setQueryData(
            ['activity', {}],
            generateActivitySampleData()
          );
        }, 0);
      }
    }

    // console.groupCollapsed(type);
    // console.log(data); //eslint-disable-line no-console
    // console.groupEnd();
  };

  const generateActivitySampleData = () => {
    return {
      pageParams: [0],
      pages: [
        {
          page: 1,
          activity: activitySampleData.map((item: any) => ({
            ...item,
            createdAt: moment(item.createdAt).dayOfYear(moment().dayOfYear()),
            updatedAt: moment(item.updatedAt).dayOfYear(moment().dayOfYear()),
          })),
        },
      ],
    };
  };

  const resetActivitySampleData = () => {
    return {
      pageParams: [0],
      pages: [
        {
          page: 1,
          activity: [],
        },
      ],
    };
  };

  if (!!isLoading || !!data?.onboardedAt || !ready) {
    return <></>;
  }

  return (
    <ReactJoyride
      styles={{
        options: style,
        tooltipTitle: { textAlign: 'left', paddingLeft: 10 },
        tooltipContent: { textAlign: 'left' },
        tooltip: {
          border: '1px solid rgba(255,255,255,0.5)',
        },
        buttonSkip: {
          border: '1px solid rgba(255,255,255,0.5)',
        },
        buttonBack: {
          color: '#00a8ff',
        },
        buttonNext: {
          backgroundColor: '#00a8ff',
          border: '1px solid rgba(255,255,255,0.9)',
        },
      }}
      // @ts-ignore
      steps={steps}
      continuous
      disableCloseOnEsc
      disableOverlayClose
      disableScrollParentFix
      hideCloseButton
      showProgress
      showSkipButton
      disableScrolling
      callback={handleJoyrideCallback}
      locale={{
        back: 'Back',
        close: 'Close',
        last: 'Finish',
        next: 'Next',
        open: 'Open the dialog',
        skip: 'Never show again',
      }}
    />
  );
};

export default Onboarding;
