import {
  Box,
  Button,
  Grid,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import area from '@turf/area';
import bbox from '@turf/bbox';
import bboxPolygon from '@turf/bbox-polygon';
import buffer from '@turf/buffer';
import center from '@turf/center';
import { lineString } from '@turf/helpers';
import TripEndImage from 'assets/trip-end.svg';
import TripPointImage from 'assets/trip-point.svg';
import deviceTypes from 'common-web/definitions/deviceTypes.json';
import { AssetType } from 'common-web/types/asset/AssetType';
import { convertUnitForAssetType, getUnitForAssetType } from 'common-web/utils';
import BoxedContainer from 'components/BoxedContainer';
import {
  LAYERS,
  LineStringLayer,
  LineStringSource,
  Point,
  PointSource,
  SOURCES,
} from 'components/Map';
import Map from 'components/Map/AltMap';
import { useTrip, useTripLocations } from 'hooks/reports/useTrips';
import { useLocalStorage } from 'hooks/useLocalStorage';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TripReports from './TripReports';
import useUIContext from 'context/UIContext';

function getDeviceFullName(id: any) {
  if (deviceTypes) {
    var config = deviceTypes.deviceTypes.filter((d: any) => d.id === id);
    return `${config?.[0]?.manufacturer} ${config?.[0]?.model}`;
  }
}

const MyTableRow = ({ label, value, labelStyle, valueStyle, onClick }: any) => {
  return (
    <TableRow>
      <TableCell
        style={{ ...labelStyle }}
        sx={{
          border: 0,
          fontSize: '0.8rem',
          fontWeight: 'bold',
          color: '#ccc',
        }}>
        {label}
      </TableCell>
      <TableCell
        onClick={onClick}
        style={{ ...valueStyle }}
        sx={{ border: 0, fontSize: '0.8rem' }}>
        {value}
      </TableCell>
    </TableRow>
  );
};

const tripPointImage: any = {
  id: 'trip-point',
  src: TripPointImage,
  type: 'svg',
};
const tripEndImage: any = { id: 'trip-end', src: TripEndImage, type: 'svg' };

const vehicleTripLayer: any = LineStringLayer({
  id: LAYERS.TRIP_VEHICLE_LINE,
  source: SOURCES.LINE_STRING,
});
const tripDetailPointLayer: any = Point({
  id: LAYERS.TRIP_DETAIL_POINT,
  source: SOURCES.TRIP_DETAIL_POINT,
  icon: 'trip-point',
  size: 0.325,
  anchor: 'bottom',
});
const tripStartPointLayer: any = Point({
  id: LAYERS.TRIP_START_POINT,
  source: SOURCES.TRIP_START_POINT,
  icon: 'trip-start',
  size: 0.325,
  anchor: 'bottom-left',
});
const tripEndPointLayer: any = Point({
  id: LAYERS.TRIP_END_POINT,
  source: SOURCES.TRIP_END_POINT,
  icon: 'trip-end',
  size: 0.325,
  anchor: 'bottom-left',
});

// const assetTripPointLayer: any = VehicleTripPoint({
//   id: LAYERS.ASSET_POINTS,
//   source: SOURCES.ASSET_LOCATIONS,
// });

const Trip = () => {
  let { id: tripId } = useParams();
  const { data } = useTrip(tripId) as any;
  const { data: tripLocationData } = useTripLocations(tripId) as any;
  const [locationBbox, setLocationBbox] = React.useState<any>(null);
  const [mapCenter, setCenter] = React.useState<any>(null);
  const [lineStringSource, setLineStringSource] = React.useState<any>();
  const [tripStartPointSource, setTripStartPointSource] = React.useState<any>();
  const [tripEndPointSource, setTripEndPointSource] = React.useState<any>();
  const [detailTripPoint, setDetailTripPoint] = React.useState<any>();
  const [detailTripPointSource, setDetailTripPointSource] =
    React.useState<any>();
  const [flyTo, setFlyTo] = React.useState<any>();
  const navigate = useNavigate();
  const [storedMapStyle, storeMapStyle] = useLocalStorage('mapStyle', '');
  // const [assetPointSource, setAssetPointSource] = React.useState<any>();
  const { isMobile } = useUIContext();

  useEffect(() => {
    if (tripLocationData) {
      const locationMapped = tripLocationData?.map(({ position }: any) => [
        parseFloat(position.lon),
        parseFloat(position.lat),
      ]);
      if (locationMapped.length === 0) return;

      const locationLineString = lineString(locationMapped);
      const tmpBbox = bboxPolygon(bbox(locationLineString));

      const locationBbox = bboxPolygon(
        bbox(
          buffer(
            locationLineString.geometry,
            //Math.max(2, parseFloat(data.distance) / 10), // easy way to do this, better would be via area
            Math.max(2, area(tmpBbox) / 1_000_000 / 1000),
            { units: 'kilometers' }
          )
        )
      );
      setLocationBbox(locationBbox);
      setCenter(center(locationBbox));
    }
  }, [tripLocationData]);

  React.useEffect(() => {
    if (!mapCenter) return;
    if (!detailTripPoint) {
      setDetailTripPointSource(PointSource(SOURCES.TRIP_DETAIL_POINT, null));
      setFlyTo({
        center: mapCenter?.geometry?.coordinates,
        zoom: 1,
        essential: true,
      });
      return;
    }

    setDetailTripPointSource(
      PointSource(SOURCES.TRIP_DETAIL_POINT, detailTripPoint)
    );
    const { lat, lon } = detailTripPoint?.position;
    if (lat && lon) setFlyTo({ center: [lon, lat], zoom: 16, essential: true });
  }, [detailTripPoint]);

  React.useEffect(() => {
    if (tripLocationData && tripLocationData.length > 0) {
      setLineStringSource(LineStringSource(tripLocationData));
      // setAssetPointSource(AssetLocationSource(tripLocationData));
      setTripStartPointSource(
        PointSource(SOURCES.TRIP_START_POINT, tripLocationData[0])
      );
      setTripEndPointSource(
        PointSource(
          SOURCES.TRIP_END_POINT,
          tripLocationData[tripLocationData.length - 1]
        )
      );
    }
  }, [tripLocationData]);

  return (
    <BoxedContainer
      style={{ ...(isMobile && { width: 'auto', paddingBottom: '200px' }) }}>
      <Box sx={{ backgroundColor: 'transparent' }}>
        <Grid container spacing={0} sx={{ py: 0.5, mb: 1 }}>
          <Grid item xs={12} md={6}>
            {(!data || !tripLocationData) && (
              <Skeleton variant='rounded' width={'100%'} height={240} />
            )}
            {data && tripLocationData && mapCenter && (
              <Map
                center={mapCenter?.geometry?.coordinates}
                flyTo={flyTo}
                hasControls={false}
                hasDraw={false}
                images={[tripEndImage, tripPointImage]}
                layers={[
                  vehicleTripLayer,
                  // assetTripPointLayer,
                  tripDetailPointLayer,
                  tripStartPointLayer,
                  tripEndPointLayer,
                ]}
                sources={[
                  lineStringSource,
                  // assetPointSource,
                  detailTripPointSource,
                  tripStartPointSource,
                  tripEndPointSource,
                ]}
                bounds={locationBbox?.bbox}
                maxBounds={true}
                zoom={1}
                mapStyle={storedMapStyle}
                style={{
                  minHeight: 240,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            {!data && (
              <Skeleton variant='rounded' width={'100%'} height={240} />
            )}
            {data && (
              <TableContainer component={Paper} sx={{ overflow: 'hidden' }}>
                <Table
                  sx={{ minWidth: 650 }}
                  size='small'
                  aria-label='a dense table'>
                  <TableBody>
                    <MyTableRow label={'Asset'} value={data.name} />
                    <MyTableRow
                      label={'Device'}
                      value={getDeviceFullName(data.deviceType)}
                    />
                    {data.assetType == AssetType.VEHICLE && data.driverUuid && (
                      <MyTableRow
                        label={'Driver'}
                        value={data.driverName || 'Unknown'}
                        valueStyle={
                          !!data.driverId && {
                            cursor: 'pointer',
                            textDecoration: 'underline',
                          }
                        }
                        onClick={() => navigate(`/drivers/${data.driverUuid}`)}
                      />
                    )}
                    <MyTableRow
                      label={'Start / End'}
                      value={(() => {
                        return (
                          <>
                            {moment(data.startTimestamp).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                            {'  /  '}
                            {moment(data.endTimestamp).format(
                              'YYYY-MM-DD HH:mm:ss'
                            )}
                          </>
                        );
                      })()}
                    />
                    <MyTableRow
                      label={'Duration'}
                      value={(() => {
                        const days = moment(data.endTimestamp).diff(
                          moment(data.startTimestamp),
                          'days'
                        );
                        const hours =
                          moment(data.endTimestamp).diff(
                            moment(data.startTimestamp),
                            'hours'
                          ) % 24;
                        const minutes =
                          moment(data.endTimestamp).diff(
                            moment(data.startTimestamp),
                            'minutes'
                          ) % 60;
                        const showMin = minutes
                          ? ` ${minutes.toString().padStart(2, '0')}m`
                          : '';
                        const showHrs = hours >= 1 ? ` ${hours}h` : '';
                        const showDays = days >= 1 ? `${days}d` : '';
                        return `${showDays}${showHrs}${showMin}`;
                      })()}
                    />
                    <MyTableRow
                      label={'Distance'}
                      value={(() => {
                        return (
                          <>
                            {convertUnitForAssetType(
                              'distance',
                              data.assetType,
                              data.distance || 0
                            )}{' '}
                            {getUnitForAssetType('distance', data.assetType)}
                          </>
                        );
                      })()}
                    />
                    {data.assetType === AssetType.VEHICLE && (
                      <MyTableRow
                        label={'CO₂'}
                        valueStyle={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        value={<>{data.carbon} Kg</>}
                      />
                    )}
                    {(data.assetType === AssetType.VEHICLE ||
                      data.assetType === AssetType.VESSEL) && (
                      <MyTableRow
                        label={'Fuel Used'}
                        valueStyle={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                        value={<>{data.fuelUsed} L</>}
                      />
                    )}
                    <MyTableRow
                      label={'From'}
                      valueStyle={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      value={
                        <>
                          {data.startFormattedAddress ??
                            `${data.startPosition.lat.toFixed(
                              7
                            )}, ${data.startPosition.lon.toFixed(7)}`}
                        </>
                      }
                    />
                    <MyTableRow
                      label={'To'}
                      valueStyle={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      value={
                        <>
                          {data.endFormattedAddress ??
                            `${data.endPosition.lat.toFixed(
                              7
                            )}, ${data.endPosition.lon.toFixed(7)}`}
                        </>
                      }
                    />
                  </TableBody>
                </Table>
                <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                  <Button
                    size='small'
                    onClick={() => {
                      navigate(`/assets/${data.assetUuid}`);
                    }}
                    sx={{
                      mb: 2,
                      mr: 2,
                      color: '#70A8CC',
                      textTransform: 'capitalize',
                      textAlign: 'right',
                    }}>
                    View asset details
                  </Button>
                </Box>
              </TableContainer>
            )}
          </Grid>
        </Grid>
        <TripReports
          onRowClicked={(e: any) => setDetailTripPoint(e)}
          tripId={tripId}
          tripData={data}
        />
      </Box>
    </BoxedContainer>
  );
};

export default Trip;
