import { CalendarMonth } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  startOfMonth,
} from 'date-fns';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DateTimeRangePickerProps {
  variant?: 'filled' | 'outlined' | 'standard';
  label?: string;
  sx?: any;
  handleChange?: Function;
  activeOption: IntervalType;
  startDate?: Date;
  endDate?: Date;
}
export enum IntervalType {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  TWENTYFOURHOURS = '24hrs',
  LAST_SEVEN_DAYS = 'last_seven_days',
  LAST_THIRTY_DAYS = 'last_thirty_days',
  LAST_THREE_MONTHS = 'last_three_months',
}

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week.
  },
});

export const formatDates = (value: string) => {
  let start = moment().startOf('day');
  let end = moment().endOf('day');

  if (value === IntervalType.TWENTYFOURHOURS) {
    start = moment().subtract(1, 'days');
    end = moment();
  } else if (value === IntervalType.TODAY) {
    start = moment().startOf('day');
    end = moment();
  } else if (value === IntervalType.YESTERDAY) {
    start = moment().startOf('day').subtract(1, 'days');
    end = moment().endOf('day').subtract(1, 'days');
  } else if (value === IntervalType.LAST_SEVEN_DAYS) {
    start = moment().startOf('day').subtract(7, 'days');
  } else if (value === IntervalType.LAST_THIRTY_DAYS) {
    start = moment().startOf('day').subtract(30, 'days');
  } else if (value === IntervalType.THIS_MONTH) {
    start = moment().startOf('month');
  } else if (value === IntervalType.THIS_WEEK) {
    start = moment().startOf('week').isoWeekday(1);
  } else if (value === IntervalType.LAST_THREE_MONTHS) {
    start = moment().startOf('day').subtract(3, 'months');
  }

  return {
    dateRange: value,
    startTimestamp: start.format(),
    endTimestamp: end.format(),
  };
};

const defaultOptions = [
  IntervalType.TODAY,
  IntervalType.YESTERDAY,
  IntervalType.THIS_WEEK,
  IntervalType.THIS_MONTH,
  IntervalType.TWENTYFOURHOURS,
  IntervalType.LAST_SEVEN_DAYS,
  IntervalType.LAST_THIRTY_DAYS,
];

export const DateTimeRangePicker = (props: DateTimeRangePickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const { t } = useTranslation();
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>(
    {
      startDate: undefined,
      endDate: undefined,
    }
  );
  const [activeOption, setActiveOption] = useState<IntervalType | 'custom'>(
    props.activeOption
  );

  useEffect(() => {
    if (props.activeOption) {
      setActiveOption(props.activeOption);
      selectDateRangeFromOption(props.activeOption);
    }
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: any) => {
    if (event.activeOption !== activeOption) {
      setActiveOption(event.activeOption);
    }
    setSelectedDateRange({
      startDate: event.startDate,
      endDate: event.endDate,
    });
  };

  useEffect(() => {
    if (props.handleChange) {
      props.handleChange({
        dateRange: activeOption,
        startTimestamp: selectedDateRange.startDate,
        endTimestamp: selectedDateRange.endDate,
      });
    }
  }, [selectedDateRange, activeOption]);

  const selectDateRangeFromOption = (option: IntervalType) => {
    if (option === IntervalType.TWENTYFOURHOURS) {
      setSelectedDateRange({
        startDate: moment().subtract(1, 'days').toDate(),
      });
    } else if (option === IntervalType.TODAY) {
      setSelectedDateRange({
        startDate: moment().startOf('day').toDate(),
      });
    } else if (option === IntervalType.YESTERDAY) {
      setSelectedDateRange({
        startDate: moment().startOf('day').subtract(1, 'days').toDate(),
        endDate: moment().endOf('day').subtract(1, 'days').toDate(),
      });
    } else if (option === IntervalType.LAST_SEVEN_DAYS) {
      setSelectedDateRange({
        startDate: moment()
          .startOf('day')
          .subtract(7, 'days')
          .set({ hour: moment().hour(), minute: moment().minute() })
          .toDate(),
      });
    } else if (option === IntervalType.LAST_THIRTY_DAYS) {
      setSelectedDateRange({
        startDate: moment()
          .startOf('day')
          .subtract(30, 'days')
          .set({ hour: moment().hour(), minute: moment().minute() })
          .toDate(),
      });
    } else if (option === IntervalType.THIS_WEEK) {
      setSelectedDateRange({
        startDate: moment().startOf('week').toDate(),
      });
    } else if (option === IntervalType.THIS_MONTH) {
      setSelectedDateRange({
        startDate: moment().startOf('month').startOf('day').toDate(),
      });
    }
  };

  const generateOptions = (t: any, options: IntervalType[]) => {
    return options.map((option: IntervalType) => {
      return (
        <MenuItem
          onClick={() => {
            setActiveOption(option);
            selectDateRangeFromOption(option);
          }}
          key={option}
          value={option}
          sx={{
            backgroundColor:
              activeOption === option
                ? 'rgba(255, 255, 255, 0.04)'
                : 'transparent',
          }}>
          {t(`date_filter.${option}`)}
        </MenuItem>
      );
    });
  };

  const renderHoursOptions = () => {
    let elements = [];
    for (let i = 0; i < 24; i++) {
      elements.push(
        <MenuItem key={i} value={i}>
          {i.toString().length < 2 ? 0 + '' + i : i}
        </MenuItem>
      );
    }
    return elements;
  };

  const renderMinutesOptions = (data: any) => {
    let elements = [];
    for (let i = 0; i < 60; i++) {
      if (i % 5 === 0) {
        elements.push(
          <MenuItem key={i} value={i}>
            {i.toString().length < 2 ? 0 + '' + i : i}
          </MenuItem>
        );
      } else if (i === getCurrentMinutesValue(data)) {
        elements.push(
          <MenuItem key={i} value={i}>
            {i.toString().length < 2 ? 0 + '' + i : i}
          </MenuItem>
        );
      } else {
        elements.push(
          <MenuItem sx={{ display: 'none' }} key={i} value={i}>
            {i.toString().length < 2 ? 0 + '' + i : i}
          </MenuItem>
        );
      }
    }
    return elements;
  };

  const getCurrentHoursValue = (data: any) => {
    if (!!data && data.getHours() !== undefined) {
      return data.getHours();
    }
    return moment().hours();
  };

  const getCurrentMinutesValue = (data: any) => {
    if (!!data && data.getMinutes() !== undefined) {
      return data.getMinutes();
    }
    return moment().minutes();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'date-time-range-picker-popover' : undefined;

  return (
    <Box sx={props.sx}>
      <Popover
        id={id}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorEl={anchorEl}
        onClose={handleClose}
        open={open}>
        <Grid container sx={{ width: 500, pb: 2 }}>
          <Grid item xs={3} sx={{ marginTop: 4 }}>
            {generateOptions(t, defaultOptions)}
          </Grid>
          <Grid item xs={9} sx={{ pl: 1 }}>
            <CalendarComponent
              startDate={selectedDateRange.startDate}
              endDate={selectedDateRange.endDate}
              activeOption={activeOption}
              onChange={handleChange}
            />
            <div
              style={{
                marginTop: '8px',
                width: '100%',
                display: 'flex',
                textAlign: 'center',
              }}>
              <div style={{ flex: 1 }}>
                <span style={{ fontSize: 12 }}>
                  Start Time:
                  <br />
                  on {selectedDateRange.startDate?.toLocaleDateString()}
                  <br />
                </span>
                <FormControl>
                  <Select
                    onChange={e =>
                      setSelectedDateRange({
                        ...selectedDateRange,
                        startDate: moment(selectedDateRange.startDate)
                          .hours(e.target.value as number)
                          .toDate(),
                      })
                    }
                    value={getCurrentHoursValue(selectedDateRange.startDate)}
                    SelectDisplayProps={{
                      style: { padding: '8px 32px 8px 18px' },
                    }}>
                    {renderHoursOptions()}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    onChange={e =>
                      setSelectedDateRange({
                        ...selectedDateRange,
                        startDate: moment(selectedDateRange.startDate)
                          .minutes(e.target.value as number)
                          .toDate(),
                      })
                    }
                    value={getCurrentMinutesValue(selectedDateRange.startDate)}
                    SelectDisplayProps={{
                      style: { padding: '8px 32px 8px 18px' },
                    }}>
                    {renderMinutesOptions(selectedDateRange.startDate)}
                  </Select>
                </FormControl>
              </div>
              <div style={{ flex: 1 }}>
                <span style={{ fontSize: 12 }}>
                  End Time:
                  <br />
                  on {selectedDateRange.endDate?.toLocaleDateString()}
                  <br />
                </span>
                <FormControl>
                  <Select
                    onChange={e =>
                      setSelectedDateRange({
                        ...selectedDateRange,
                        endDate: moment(selectedDateRange.endDate)
                          .hours(e.target.value as number)
                          .toDate(),
                      })
                    }
                    value={getCurrentHoursValue(selectedDateRange.endDate)}
                    SelectDisplayProps={{
                      style: { padding: '8px 32px 8px 18px' },
                    }}>
                    {renderHoursOptions()}
                  </Select>
                </FormControl>
                <FormControl>
                  <Select
                    onChange={e =>
                      setSelectedDateRange({
                        ...selectedDateRange,
                        endDate: moment(selectedDateRange.endDate)
                          .minutes(e.target.value as number)
                          .toDate(),
                      })
                    }
                    value={getCurrentMinutesValue(selectedDateRange.endDate)}
                    SelectDisplayProps={{
                      style: { padding: '8px 32px 8px 18px' },
                    }}>
                    {renderMinutesOptions(selectedDateRange.endDate)}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
        </Grid>
      </Popover>

      <TextField
        fullWidth
        size='small'
        variant={props?.variant ?? 'outlined'}
        label={props?.label ?? 'Period'}
        id='date-time-range-picker-field'
        value={t('date_filter.' + activeOption)}
        placeholder={'asd'}
        InputProps={{
          style: {
            cursor: 'pointer',
            caretColor: 'transparent',
          },
          endAdornment: (
            <InputAdornment position='end'>
              <CalendarMonth />
            </InputAdornment>
          ),
        }}
        sx={{
          ...props.sx,
          input: { cursor: 'pointer' },
        }}
        style={{
          cursor: 'pointer',
        }}
        onClick={handleClick}
      />
    </Box>
  );
};

export default DateTimeRangePicker;

interface SelectedDateRange {
  startDate: Date | undefined;
  endDate?: Date | undefined;
}

export const CalendarComponent = ({
  startDate,
  endDate,
  activeOption,
  onChange,
}: {
  startDate?: Date;
  endDate?: Date;
  activeOption: IntervalType | 'custom';
  onChange: Function;
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDateRange, setSelectedDateRange] = useState<SelectedDateRange>(
    { startDate, endDate }
  );
  const [hoveredDate, setHoveredDate] = useState(null);
  const [lastDateTypeSelected, setLastDateTypeSelected] = useState<
    'start' | 'end' | null
  >(null);
  const [clickHistory, setClickHistory] = useState<Date[]>([
    new Date(),
    new Date(),
  ]);

  useEffect(() => {
    setSelectedDateRange({ startDate, endDate });
  }, [startDate, endDate]);

  useEffect(() => {
    if (onChange) {
      onChange({
        ...selectedDateRange,
        activeOption,
      });
    }
  }, [selectedDateRange]);

  const notBefore = moment().subtract(1, 'year').subtract(1, 'month');

  const handleMonthChange = (increment: any) => {
    const newMonth = addMonths(currentMonth, increment);
    if (notBefore.isAfter(moment(newMonth))) return;
    setCurrentMonth(newMonth);
  };

  const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  const handleDateClick = (clickedDate: Date) => {
    let { startDate, endDate } = selectedDateRange;

    if (onChange) {
      onChange({
        ...selectedDateRange,
        activeOption: 'custom',
      });
    }

    // Update click history
    if (clickHistory.length === 2) {
      clickHistory.shift();
    }
    clickHistory.push(clickedDate);
    setClickHistory([...clickHistory]);

    if (
      (startDate && moment(clickedDate).isSame(moment(startDate))) ||
      (endDate && moment(clickedDate).isSame(moment(endDate)))
    ) {
      setSelectedDateRange({
        startDate: clickedDate,
        endDate: moment(clickedDate).endOf('day').toDate(),
      });
      setLastDateTypeSelected(null);
    } else if (!startDate) {
      setSelectedDateRange({ ...selectedDateRange, startDate: clickedDate });
      setLastDateTypeSelected('start');
    } else if (startDate && !endDate) {
      if (clickedDate < startDate) {
        setSelectedDateRange({
          startDate: clickedDate,
          endDate: moment(startDate).endOf('day').toDate(),
        });
      } else {
        setSelectedDateRange({
          ...selectedDateRange,
          endDate: moment(clickedDate).endOf('day').toDate(),
        });
      }
      setLastDateTypeSelected('end');
    } else if (startDate && endDate) {
      // Adjustment based on user actions
      if (moment(startDate).isAfter(moment(clickedDate))) {
        setSelectedDateRange({ startDate: clickedDate, endDate });
        setLastDateTypeSelected('start');
      } else if (moment(endDate).isBefore(clickedDate)) {
        setSelectedDateRange({
          startDate,
          endDate: moment(clickedDate).endOf('day').toDate(),
        });
        setLastDateTypeSelected('end');
      } else {
        if (lastDateTypeSelected === 'end') {
          setSelectedDateRange({ startDate: clickedDate, endDate });
          setLastDateTypeSelected('start');
        } else {
          setSelectedDateRange({
            startDate,
            endDate: moment(clickedDate).endOf('day').toDate(),
          });
          setLastDateTypeSelected('end');
        }
      }
    }
  };

  const handleDateHover = (date: any) => {
    setHoveredDate(date);
  };

  const getCellStyle = useCallback(
    (date: Date) => {
      const { startDate, endDate } = selectedDateRange;
      const momentDate = moment(date);
      const isStartDate = startDate && momentDate.isSame(startDate, 'day');
      const isEndDate = endDate && momentDate.isSame(endDate, 'day');
      const isBetween =
        startDate &&
        momentDate.isAfter(startDate, 'day') &&
        momentDate.isBefore(endDate || moment(), 'day');

      const cellStyle = {
        backgroundColor: 'transparent',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        color: isStartDate || isEndDate || isBetween ? 'white' : '',
      };

      if (isStartDate) {
        cellStyle.backgroundColor = 'rgba(3, 138, 255, 1)';
        cellStyle.borderTopLeftRadius = 8;
        cellStyle.borderBottomLeftRadius = 8;
      }

      if (isEndDate) {
        cellStyle.backgroundColor = 'rgba(3, 138, 255, 1)';
        cellStyle.borderTopRightRadius = 8;
        cellStyle.borderBottomRightRadius = 8;
      }

      if (isBetween) {
        cellStyle.backgroundColor = 'rgba(3, 138, 255, 0.5)';
      }

      if (isStartDate && isEndDate) {
        // This covers the case where start and end date are the same
        cellStyle.borderTopLeftRadius = 8;
        cellStyle.borderTopRightRadius = 8;
        cellStyle.borderBottomRightRadius = 8;
        cellStyle.borderBottomLeftRadius = 8;
      }

      if (!endDate) {
        if (momentDate.isSame(moment(), 'day')) {
          cellStyle.backgroundColor = 'rgba(3, 138, 255, 1)';
          cellStyle.color = 'white';
          cellStyle.borderTopRightRadius = 8;
          cellStyle.borderBottomRightRadius = 8;
        }
      }

      return cellStyle;
    },
    [activeOption, selectedDateRange]
  );

  const daysInMonth = eachDayOfInterval({
    start: startOfMonth(currentMonth),
    end: endOfMonth(currentMonth),
  });

  // Find the starting day of the week for the current month
  const startDay = getDay(startOfMonth(currentMonth));
  const endDay = getDay(endOfMonth(currentMonth));

  // Create an array of blank cells to adjust the start position of the first date
  const blankCellsBefore = Array(startDay === 0 ? 6 : startDay - 1).fill(null);
  const blankCellsAfter = Array(endDay === 0 ? 0 : 7 - endDay).fill(null);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          width: '100%',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          flexDirection: 'row',
        }}>
        <Button onClick={() => handleMonthChange(-1)}>{'<'}</Button>
        <Typography variant='h6'>
          {format(currentMonth, 'MMMM yyyy')}
        </Typography>
        <Button onClick={() => handleMonthChange(1)}>{'>'}</Button>
      </Grid>
      <Grid item container xs={12} sx={{ display: 'flex' }}>
        {daysOfWeek.map(day => (
          <Grid item xs sx={{ flexGrow: 1, maxWidth: '14.28%' }} key={day}>
            <Typography variant='subtitle1' style={{ textAlign: 'center' }}>
              {day}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item container xs={12} sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {/* Insert blank cells for alignment */}
        {blankCellsBefore.map((_, index) => (
          <Grid
            item
            xs
            sx={{ flexGrow: 1, maxWidth: '14.28%', minWidth: '14.28%' }}
            key={`blank-${index}`}>
            <Button disabled sx={{ visibility: 'hidden' }} />
          </Grid>
        ))}
        {daysInMonth.map((date, index) => (
          <Grid
            item
            xs
            sx={{
              flexGrow: 1,
              maxWidth: '14.28%',
              minWidth: '14.28%',
            }}
            style={{
              width: '14.28%',
            }}
            key={date.toString()}>
            <Button
              disabled={moment(date).isAfter(moment())}
              onClick={() => handleDateClick(date)}
              onMouseOver={() => handleDateHover(date)}
              onMouseOut={() => handleDateHover(null)}
              style={{ minWidth: '100%', maxWidth: '100%' }}
              sx={{ px: 0 }}>
              <span
                style={{
                  width: '100%',
                  height: '80%',
                  display: 'block',
                  ...getCellStyle(date),
                }}>
                {format(date, 'd')}
              </span>
            </Button>
          </Grid>
        ))}
        {blankCellsAfter.map((_, index) => (
          <Grid
            item
            xs
            sx={{ flexGrow: 1, maxWidth: '14.28%', minWidth: '14.28%' }}
            key={`blank-${index}`}>
            <Button disabled sx={{ visibility: 'hidden' }} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
