import { Grid } from '@mui/material';
import { IntervalType } from 'components/Filters/DateFilter';
import CreateReportButton from 'components/ScheduledReports/CreateReportButton';
import Search from 'components/Search';
import WithUrlFilters from 'components/TableToolbar/WithUrlFilters';
import { ScheduledReportType } from 'hooks/reports/useScheduledReport';
import React from 'react';
import { DateFilter, GroupFilter } from '../../components/Filters';

const DriverStatusFilters = (props: any) => {
  const dateOptions = [
    IntervalType.LAST_SEVEN_DAYS,
    IntervalType.LAST_THIRTY_DAYS,
    IntervalType.LAST_THREE_MONTHS,
  ];

  return (
    <Grid container spacing={2} sx={{ py: 0.5, mt: 0, mb: 2 }}>
      <Grid item xs={3}>
        <Search
          translationKey='driver_search.placeholder'
          setSearchTerm={props.setSearchTerm}
        />
      </Grid>
      <Grid item xs={2}>
        <DateFilter
          {...props}
          sx={{ mr: 1 }}
          dateOptions={dateOptions}
          selectedDateOption={IntervalType.LAST_THIRTY_DAYS}
        />
      </Grid>
      <Grid item xs={2}>
        <GroupFilter {...props} sx={{ ml: 1 }} multiple={true} />
      </Grid>
      <Grid item xs={5} sx={{ display: 'flex', justifyContent: 'end' }}>
        <CreateReportButton type={ScheduledReportType.DRIVERS} />
        <props.exportComponent filters={props.filters} />
      </Grid>
    </Grid>
  );
};

const DriverStatusToolbarWithUrlFilters = (props: any) => {
  return (
    <WithUrlFilters>
      <DriverStatusFilters {...props} />
    </WithUrlFilters>
  );
};

export default DriverStatusToolbarWithUrlFilters;
